import React, {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import ProductCard, {AddProductCard} from "./ProductCard";
import {Avatar, Grid, IconButton, Typography} from "@mui/material";
import Pagination from "../../components/Pagination/Pagination";
import {useApi, useLoginStore} from "../../services/helpers";
import SearchBar from "../../components/SearchBar";
import {changeState} from "../../utils/helpers";
import {useApiMessages} from "../../services/api/api-messages";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";
import {Box} from "@mui/system";
import {AttachMoney, MoneyOff} from "@mui/icons-material";

const INITIAL_STATE = {
  counts: 0,
  itemsPerPage: 0,
  currentPage: 1,
  products: [],
  search: '',
  loading: false,
  selectedProduct: null,
  deleteModal: false,
  buyModal: false,
  selectedTab: 0,
  myProducts: []
}

const MarketplaceHome = () => {
  const api = useApi();
  const {teacher, school_admin, student} = useLoginStore();
  const messages = useApiMessages('product', 'products')
  const [pagSt, setPagSt] = useState(INITIAL_STATE);
  const PRODUCTS_PER_PAGE = teacher ? 11 : 12;
  const refInput = React.createRef();


  const searchProducts = (search) => {
    if (pagSt.selectedTab === 1) {
      changeState(setPagSt, 'search', search)
      getMyProducts({search: search})
    } else {
      changeState(setPagSt, 'search', search)
      getMarketplaceProducts({search: search})
    }
  }

  const getMarketplaceProducts = (searchParams) => {
    changeState(setPagSt, 'loading', true)
    api.getMarketplaceProducts({...searchParams, page_size: PRODUCTS_PER_PAGE}).handle({
      onSuccess: (result) => {
        const {count, results} = result.data
        setPagSt({
          ...pagSt,
          products: results,
          counts: count,
          itemsPerPage: results.length,
          currentPage: searchParams.page || pagSt.currentPage,
        });
      },
      errorMessage: "Error getting courses",
      // onError: (err) => {
      //   console.log('err ', err);
      // }
      onFinally: () => changeState(setPagSt, 'loading', false)
    });
  };

  const deleteProduct = () => {
    changeState(setPagSt, 'loading', true)
    api.deleteProduct(pagSt.selectedProduct).handle({
      onSuccess: () => {
        getMarketplaceProducts({search: ''})
        changeState(setPagSt, 'deleteModal', false)
      },
      successMessage: messages.delete.success,
      errorMessage: messages.delete.error,
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt, 'loading', false)
    });

  }

  const buyProduct = () => {
    changeState(setPagSt, 'loading', true)
    api.buyProduct(pagSt.selectedProduct).handle({
      errorMessage: 'Error buying product',
      onSuccess: () => {
        getMarketplaceProducts({search: ''})
        changeState(setPagSt, 'buyModal', false)
      },
      successMessage: 'Product bought successfully',
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt, 'loading', false)
    });
  }

  const getMyProducts = (searchParams) => {
    api.getMyProducts(searchParams).handle({
      onSuccess: (result) => {
        const {count, results} = result.data
        setPagSt({
          ...pagSt,
          myProducts: results,
          counts: count,
          itemsPerPage: results.length,
          currentPage: searchParams.page || pagSt.currentPage,
        });
      },
      errorMessage: 'Error getting products',
    })
  }

  const deductProduct = (productId) => {
    api.deductProduct(productId).handle({
      onSuccess: (res) => {
        getMyProducts()
      },
      errorMessage: 'Error deducting product',
    })
  }

  const preBuyProduct = (id) => {
    changeState(setPagSt, 'selectedProduct', id)
    changeState(setPagSt, 'buyModal', true)
  }

  const preDeleteProduct = (id) => {
    changeState(setPagSt, 'selectedProduct', id)
    changeState(setPagSt, 'deleteModal', true)
  }

  const handleClearSearch = () => {
    if (refInput.current) {
      refInput.current.clearInput(); // Call clearInput from SearchBar
    }
  };


  useEffect(() => {
    handleClearSearch();
    if (pagSt.selectedTab === 1) {
      getMyProducts({search: ''});
    } else {
      getMarketplaceProducts({search: ''});
    }

  }, [pagSt.selectedTab]);

  const renderItems = (item) => {
    return (
      <Grid key={item.id} item xs={12} sm={6} lg={3} mt={4}>
        <ProductCard
          name={item.name}
          description={item.description}
          image={item.image}
          price={item.price}
          stock={item.stock}
          id={item.id}
          is_from_user={item.is_from_user}
          course={item.course}
          buyProduct={preBuyProduct}
          deleteProduct={preDeleteProduct}
        />
      </Grid>
    )
  }

  const renderProduct = (product) => {
    console.log('product ', product);
    return (
      <Grid
        item
        xs={12} sm={6} lg={4}
        mt={6}
        key={product.id}
        sx={{
          padding: '0!important'
        }}
      >
        <Box
          display="flex"
          flexDirection={'row'}
          sx={{ maxWidth: '465px' }}
          borderRadius={2}
          bgcolor="rgba(106, 153, 78, 0.1)"
          border="1px solid lightgray"
        >
          <Box display="flex" alignItems="center" px={1} bgcolor="#fff" borderRadius={2}>
            <Avatar src={product.image}/>
          </Box>
          <Box p={1} flex={1}>
            <Typography fontSize="18px" color="primary">
              {product.name} - Price: {product.price} - Quantity {product.quantity}
            </Typography>
            {student && <Typography fontSize="14px">Sold by: {product?.seller}</Typography>}
            {!student &&<Typography fontSize="14px">Student: {product.student}</Typography>}
          </Box>
          <IconButton
            size="small"
            title={'Deduct item'}
            disabled={student || product.deducted}
            onClick={
              () => {
                if (product.deducted || student !== null) return
                deductProduct(product.id)
              }
            }>
            {product.deducted ? <MoneyOff sx={{transform: "scale(1.5)"}}/> :
              <AttachMoney sx={{transform: "scale(1.5)"}}/>}
          </IconButton>
        </Box>

      </Grid>
    )
  }

  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" gap={3}>
        <MDTypography
          variant="h3"
          color="dark"
          fontWeight="regular"
          sx={{
            textDecoration: pagSt.selectedTab === 0 ? 'underline' : 'none',
            textDecorationColor: '#6A994E',
            cursor: 'pointer'
          }}
          onClick={() => changeState(setPagSt, 'selectedTab', 0)}
        >
          Marketplace
        </MDTypography>
        <MDTypography
          flex={1}
          variant="h3"
          color="dark"
          fontWeight="regular"
          sx={{
            textDecoration: pagSt.selectedTab === 1 ? 'underline' : 'none',
            textDecorationColor: '#6A994E',
            cursor: 'pointer'
          }}
          onClick={() => changeState(setPagSt, 'selectedTab', 1)}
        >
          My Stuff
        </MDTypography>
        <SearchBar
          ref={refInput}
          loading={pagSt.loading}
          setSearchQuery={searchProducts}
        />

        {/*<MDButton variant="contained" onClick={() => {}}>*/}
        {/*  New Category*/}
        {/*</MDButton>*/}
      </MDBox>
      {pagSt.selectedTab === 0 && <MDBox px={3}>
        <Grid container mt={3} spacing={{xs: 0, md: 4}}>
          {(teacher || school_admin) && <Grid item xs={12} sm={6} lg={3} mt={4}>
            <AddProductCard/>
          </Grid>}
          {pagSt.products.map((item) => renderItems(item))}
        </Grid>
        {pagSt.products.length === 0 &&
          <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} height={'500px'}>
            <MDTypography variant={'title'} color={'primary'}>No items found</MDTypography>
          </MDBox>}
      </MDBox>}
      {pagSt.selectedTab === 1 && <MDBox px={3}>
        <Grid container mt={3} spacing={{xs: 0, md: 4}}>
          {pagSt.myProducts.map((item) => renderProduct(item))}
        </Grid>
        {pagSt.myProducts.length === 0 &&
          <MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} height={'500px'}>
            <MDTypography variant={'title'} color={'primary'}>No items found</MDTypography>
          </MDBox>}
      </MDBox>}
      <Pagination
        currentPage={pagSt.currentPage}
        totalCount={pagSt.counts}
        pageSize={PRODUCTS_PER_PAGE}
        onPageChange={page => {
          getMarketplaceProducts({...{search: pagSt.search}, page: page})
        }}
      />
      <ConfirmModal
        handleClose={() => changeState(setPagSt, 'deleteModal', false)}
        open={pagSt.deleteModal}
        handleConfirm={deleteProduct}
        title={'Delete Product'}
        subtitle={'Are you sure you want to delete this product?'}
      />
      <ConfirmModal
        handleClose={() => changeState(setPagSt, 'buyModal', false)}
        open={pagSt.buyModal}
        handleConfirm={buyProduct}
        title={'Buy Product'}
        subtitle={'Are you sure you want to buy this product?'}
      />
    </>
  );
};

export default MarketplaceHome;
