import { Grid } from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import ModalItem from "components/ModalItem";
import { Form, Formik } from "formik";
import { useRef, useState } from "react";
import { useApi } from "../../services/helpers";
import * as Yup from "yup";

const NewLessonModal = ({ showModal, courseSelected, handleClose, onCreated }) => {
  const api = useApi();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required('This field is required'),
  });

  const createLesson = (data) => {
    setLoading(true);
    api.createLesson(courseSelected?.id || courseSelected, data).handle({
      onSuccess: (result) => {
        handleClose();
        onCreated();
      },
      errorMessage: "Error creating lesson",
      successMessage: "Lesson created successfully!",
      onFinally: () => setLoading(false),
    });
  };

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height="auto"
      title="Add Lesson"
      handleClose={() => handleClose()}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ name: "", description: "" }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={false}
        onSubmit={(values) => createLesson(values)}
      >
        {({ errors, handleSubmit }) => (
          <Form style={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={12}>
                <FormikInput name="name" placeholder="Name" errors={errors} fullWidth />
              </Grid>
              <Grid item xs={12}>
                <FormikInput
                  name="description"
                  placeholder="Topic"
                  errors={errors}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <MDButton
                  color="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  Add Lesson
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ModalItem>
  );
};

export default NewLessonModal;
