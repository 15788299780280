import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import ModalItem from "components/ModalItem";
import { Form, Formik, useFormikContext } from "formik";
import React, { useEffect, useRef, useState } from "react";
import dpad from "../../assets/images/dpad.svg";
import buttons from "../../assets/images/game-buttons.svg";
import { ArrowDropDown, ArrowDropUp, ArrowLeft, ArrowRight } from "@mui/icons-material";
import {
  TYPE_OF_QUESTIONNAIRE_OPTIONS,
  TYPE_OF_INTERACTION_OPTIONS,
  BUTTON_TYPE,
  TYPE_OF_QUESTION_ABOUT,
  TYPE_OF_MOMENT_POPUP,
} from "../../utils/helpers";
import * as Yup from "yup";
import PaidIcon from "@mui/icons-material/Paid";
import MDBox from "../../components/MDBox";
import { v4 as uuidv4 } from "uuid";
import DeleteIcon from "@mui/icons-material/Delete";

const QuestionModal = ({ showModal, handleClose, onCreated, onUpdate, question, isWB = false }) => {
  const formikRef = useRef();
  console.log("question is:", question);
  const initialValues = {
    prompt: question?.prompt || "",
    question_type: question?.question_type || TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN,
    answer_type: question?.answer_type || TYPE_OF_INTERACTION_OPTIONS.DPAD,
    answers: question?.answers || [],
    reward: question?.reward || 0,
    randomize_answers: question?.randomize_answers || false,
    timeout: question?.timeout || null,
    question_about: question?.question_about || isWB ? TYPE_OF_QUESTION_ABOUT.REFLECTION : null,
    question_pop_up: question?.question_pop_up || isWB ? TYPE_OF_MOMENT_POPUP.CHECK_IN : null,
  };

  const validationSchema = Yup.object().shape({
    prompt: Yup.string().required(),
    question_type: Yup.mixed().oneOf(Object.values(TYPE_OF_QUESTIONNAIRE_OPTIONS)).required(),
    answer_type: Yup.number().when("question_type", {
      is: TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE,
      then: Yup.number().required(),
      otherwise: Yup.number().nullable(),
    }),
    answers: Yup.array().when("question_type", {
      is: TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE,
      then: Yup.array()
        .of(
          Yup.object().shape({
            answer: Yup.string().required(),
            button_type: Yup.mixed().oneOf(Object.values(BUTTON_TYPE)).required(),
            correct: Yup.boolean().required().default(false),
            reward: Yup.number().min(0).required(),
          })
        )
        .min(1),
      otherwise: Yup.array().nullable(),
    }),
    reward: Yup.number().when(
      ["question_type", "answers"],
      (question_type, answers, validationSchema) => {
        return question_type === TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN && answers === []
          ? validationSchema.required().min(0)
          : validationSchema.nullable();
      }
    ),
    timeout: Yup.number().notRequired().nullable(),
    question_about: Yup.number().when("$isWB", {
      is: true,
      then: Yup.number().required(),
      otherwise: Yup.number().nullable(),
    }),
    question_pop_up: Yup.number().when("$isWB", {
      is: true,
      then: Yup.number().required(),
      otherwise: Yup.number().nullable(),
    }),
  });

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height="80%"
      title="Add Question"
      handleClose={handleClose}
      width={"90%"}
      widthContainer={"100%"}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnBlur={false}
        contect={isWB}
        onSubmit={(values) => (question && question.id ? onUpdate(values) : onCreated(values))}
        // onSubmit={(values) => console.log("values are:", values)}
        checked={true}
      >
        {({ values, errors, handleSubmit, setFieldValue, isValid, dirty, resetForm }) => {
          console.log("errors", errors);
          return (
            <Form style={{ width: "100%" }}>
              <Grid container rowGap={"25px"} px={"15px"}>
                <Grid item xs={12} mt={3}>
                  <MDTypography color="dark" fontSize={{ xs: "18px", md: "24px" }} mr={2}>
                    Type of questionnaire
                  </MDTypography>
                  <Box ml={1}>
                    <RadioGroup
                      defaultValue={question?.question_type || TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN}
                      row
                      name="row-radio-buttons-group"
                      onClick={(e) => {
                        const code = parseInt(e.target.value);
                        setFieldValue("question_type", code);
                        code === TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE
                          ? resetForm({
                              values: {
                                prompt: values.prompt,
                                timeout: null,
                                question_type: TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE,
                                answer_type: TYPE_OF_INTERACTION_OPTIONS.DPAD,
                                answers: values.answers.splice(0, 4),
                                reward: undefined,
                              },
                            })
                          : resetForm({
                              values: {
                                prompt: values.prompt,
                                timeout: null,
                                question_type: TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN,
                                answer_type: TYPE_OF_INTERACTION_OPTIONS.DPAD,
                                answers: values.answers,
                                reward: 0,
                              },
                            });
                      }}
                    >
                      {Object.entries(TYPE_OF_QUESTIONNAIRE_OPTIONS._LABELS).map(([key, label]) => (
                        <FormControlLabel key={key} value={key} control={<Radio />} label={label} />
                      ))}
                    </RadioGroup>
                  </Box>
                </Grid>
                {values.question_type === TYPE_OF_QUESTIONNAIRE_OPTIONS.MULTIPLE_CHOICE && (
                  <MultipleChoiceQuestion question={question} isWB={isWB} />
                )}
                {values.question_type === TYPE_OF_QUESTIONNAIRE_OPTIONS.OPEN && (
                  <OpenEndedQuestion question={question} />
                )}

                {isWB && (
                  <>
                    <Grid item xs={12} mt={3} display="flex">
                      <MDTypography
                        color="dark"
                        fontSize={{ xs: "18px", md: "24px" }}
                        flexDirection="row"
                        mr={2}
                      >
                        Select question about:
                      </MDTypography>
                      <RadioGroup
                        defaultValue={question?.question_about || TYPE_OF_QUESTION_ABOUT.REFLECTION}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group2"
                        sx={{ ml: 1 }}
                        onChange={(e) => setFieldValue("question_about", parseInt(e.target.value))}
                      >
                        {Object.entries(TYPE_OF_QUESTION_ABOUT._LABELS).map(([key, label]) => (
                          <FormControlLabel
                            key={key}
                            value={key}
                            control={<Radio />}
                            sx={{ display: "flex" }}
                            label={
                              <Box display="flex" gap={1}>
                                <MDTypography color="dark" variant="body2">
                                  {label}
                                </MDTypography>
                              </Box>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                    <Grid item xs={12} mt={3} display="flex">
                      <MDTypography
                        color="dark"
                        fontSize={{ xs: "18px", md: "24px" }}
                        flexDirection="row"
                        mr={2}
                      >
                        Select pop-up moment:
                      </MDTypography>
                      <RadioGroup
                        defaultValue={question?.question_pop_up || TYPE_OF_MOMENT_POPUP.CHECK_IN}
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group2"
                        sx={{ ml: 1 }}
                        onChange={(e) => setFieldValue("question_pop_up", parseInt(e.target.value))}
                      >
                        {Object.entries(TYPE_OF_MOMENT_POPUP._LABELS).map(([key, label]) => (
                          <FormControlLabel
                            key={key}
                            value={key}
                            control={<Radio />}
                            sx={{ display: "flex" }}
                            label={
                              <Box display="flex" gap={1}>
                                <MDTypography color="dark" variant="body2">
                                  {label}
                                </MDTypography>
                              </Box>
                            }
                          />
                        ))}
                      </RadioGroup>
                    </Grid>
                  </>
                )}

                <Grid item xs={12} mt={3} display="flex" justifyContent="center">
                  <MDButton color="primary" onClick={handleSubmit} disabled={!isValid}>
                    Save
                  </MDButton>
                </Grid>
              </Grid>
            </Form>
          );
        }}
      </Formik>
    </ModalItem>
  );
};

const OpenEndedQuestion = ({ question }) => {
  const { errors, values, setFieldValue } = useFormikContext();
  const EMPTY_ANSWER = {
    tempId: uuidv4(),
    answer: "",
    button_type: null,
    correct: false,
    reward: 0,
  };

  return (
    <>
      <Grid item xs={12} mt={0}>
        <MDTypography color="dark" fontSize={{ xs: "18px", md: "24px" }} flexDirection="row" mr={2}>
          Enter the question
        </MDTypography>
        <MDBox display={"flex"} justifyContent="space-between">
          <FormikInput name="prompt" placeholder="Question" width={"75%"} errors={errors} />
          {values.answers.length <= 0 && (
            <FormikInput
              name="reward"
              type={"number"}
              placeholder="0"
              width={"20%"}
              errors={errors}
              InputProps={{
                inputProps: { min: 0 },
                endAdornment: (
                  <InputAdornment position="end">
                    <PaidIcon color="primary" />
                  </InputAdornment>
                ),
              }}
            />
          )}
        </MDBox>
      </Grid>

      <Grid item xs={12}>
        <MDButton
          onClick={() => {
            setFieldValue("reward", undefined);
            setFieldValue("answers", [...values.answers, EMPTY_ANSWER]);
          }}
        >
          Add Option
        </MDButton>
      </Grid>
      <Grid item xs={12}>
        {values.answer_type &&
          values.answers?.map((answer, i) => (
            <PossibleAnswerField
              key={answer?.id || answer?.tempId}
              i={i}
              onChange={(value, bucks) => {
                values.answers[i] = { ...values.answers[i], answer: value, reward: bucks };
                setFieldValue("answers", values.answers);
              }}
              checked={values.answers[i].correct === true}
              onSelectOption={() => {
                setFieldValue(
                  "answers",
                  values.answers.map((a, index) =>
                    i === index ? { ...a, correct: !a.correct } : a
                  )
                );
              }}
              option={values.answers[i]}
              onDelete={() => {
                values.answers.splice(i, 1);
                setFieldValue("answers", values.answers);
              }}
            />
          ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <MDTypography color="dark" fontSize={{ xs: "12px", md: "18px" }} flexDirection="row" mr={2}>
          Answer time limit
        </MDTypography>
        <FormikInput
          type="number"
          name="timeout"
          placeholder="Response Time (seconds)"
          errors={errors}
          fullWidth
        />
      </Grid>
    </>
  );
};

const MultipleChoiceQuestion = ({ question, isWB = false }) => {
  const { errors, setFieldValue, values } = useFormikContext();
  const EMPTY_ANSWER = {
    tempId: uuidv4(),
    answer: "",
    button_type: null,
    correct: false,
    reward: 0,
  };
  return (
    <>
      <Grid item xs={12} display={"flex"} flexDirection={"column"} rowGap={"15px"}>
        <Box>
          <MDTypography
            color="dark"
            fontSize={{ xs: "18px", md: "24px" }}
            flexDirection="row"
            mr={2}
          >
            Select the type of interaction
          </MDTypography>

          <RadioGroup
            defaultValue={question?.answer_type || TYPE_OF_INTERACTION_OPTIONS.DPAD}
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group2"
            sx={{ ml: 1 }}
            onChange={(e) => setFieldValue("answer_type", parseInt(e.target.value))}
          >
            {Object.entries(TYPE_OF_INTERACTION_OPTIONS._LABELS).map(([key, label]) => (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio />}
                sx={{ display: "flex" }}
                label={
                  <Box display="flex" gap={1}>
                    {key === TYPE_OF_INTERACTION_OPTIONS.BUTTONS.toString() ? (
                      <img src={buttons} width="25px" alt="buttons" />
                    ) : key === TYPE_OF_INTERACTION_OPTIONS.DPAD.toString() ? (
                      <img src={dpad} width="25px" alt="dpad" />
                    ) : (
                      <>
                        <img src={buttons} width="25px" alt="buttons" />
                        <img src={dpad} width="25px" alt="dpad" />
                      </>
                    )}
                    <MDTypography color="dark" variant="body2">
                      {label}
                    </MDTypography>
                  </Box>
                }
              />
            ))}
          </RadioGroup>
        </Box>
        <Box display={"flex"} flexDirection={"column"} rowGap={"15px"}>
          <MDTypography
            color="dark"
            fontSize={{ xs: "18px", md: "20px" }}
            flexDirection="row"
            mr={2}
          >
            Enter The Multiple Choice Question
          </MDTypography>
          <FormikInput name="prompt" placeholder="Question" errors={errors} fullWidth />
        </Box>
        <Box display={"flex"}>
          <MDTypography color={"dark"}>Randomize question</MDTypography>
          <Checkbox
            checked={values?.randomize_answers}
            onClick={() => setFieldValue("randomize_answers", !values.randomize_answers)}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        <MDButton
          disabled={
            values.answer_type === TYPE_OF_INTERACTION_OPTIONS.BOTH
              ? values.answers?.length >= 8
              : values.answers?.length >= 4
          }
          onClick={() => setFieldValue("answers", [...values.answers, EMPTY_ANSWER])}
        >
          Add Option
        </MDButton>
      </Grid>
      <Grid item xs={12}>
        {values.answer_type &&
          values.answers?.map((answer, i) => (
            <OptionField
              key={answer?.id || answer?.tempId}
              i={i}
              onChange={(value, bucks) => {
                values.answers[i] = {
                  ...values.answers[i],
                  answer: value,
                  reward: bucks,
                  button_type: icons[values.answer_type]?.[i]?.id,
                };
                setFieldValue("answers", values.answers);
              }}
              checked={values.answers[i].correct === true}
              onSelectOption={() =>
                setFieldValue(
                  "answers",
                  values.answers.map((a, index) =>
                    i === index ? { ...a, correct: !a.correct } : a
                  )
                )
              }
              icon={icons[values.answer_type]?.[i]?.component}
              option={values.answers[i]}
              onDelete={() => {
                values.answers.splice(i, 1);
                setFieldValue(
                  "answers",
                  values.answers.map((answer, index) => ({
                    ...answer,
                    button_type: icons[values.answer_type]?.[index]?.id,
                  }))
                );
              }}
              isWB={isWB}
            />
          ))}
      </Grid>
      <Grid item xs={12} md={6}>
        <MDTypography color="dark" fontSize={{ xs: "12px", md: "18px" }} flexDirection="row" mr={2}>
          Answer time limit
        </MDTypography>
        <FormikInput
          type="number"
          name="timeout"
          placeholder="Response Time (seconds)"
          errors={errors}
        />
      </Grid>
    </>
  );
};

export const OptionField = ({
  i,
  checked,
  onChange,
  onSelectOption,
  icon,
  option,
  onDelete,
  isWB = false,
}) => {
  const [value, setValue] = useState(option?.answer || "");
  const [bucks, setBucks] = useState(option?.reward || 0);

  useEffect(() => {
    onChange(value, bucks);
  }, [value, bucks]);

  return (
    <Box display={"flex"} gap={5} justifyContent={"space-between"} height={"29px"} mb={"10px"}>
      <Box display="flex" width={"60%"} alignItems="center">
        {/*<ClearIcon color="error" onClick={onDelete} sx={{cursor: "pointer"}}/>*/}
        {icon}
        <TextField
          fullWidth
          variant="standard"
          sx={{ p: 2 }}
          value={value}
          placeholder={`Option ${i + 1}`}
          onChange={(e) => setValue(e.target.value)}
        />
        {!isWB && <Checkbox checked={checked} onClick={onSelectOption} />}
      </Box>
      <Box display="flex" width={"40%"} alignItems="center">
        <TextField
          fullWidth
          multiline={false}
          variant="standard"
          type="number"
          sx={{ p: 2, border: "none" }}
          value={bucks}
          placeholder={"0"}
          onChange={(e) => setBucks(parseInt(e.target.value))}
        />
        <PaidIcon color="primary" />
        <DeleteIcon onClick={onDelete} sx={{ cursor: "pointer", ml: 2 }} />
      </Box>
    </Box>
  );
};

export const PossibleAnswerField = ({ i, checked, onChange, onSelectOption, option, onDelete }) => {
  const [value, setValue] = useState(option?.answer || "");
  const [bucks, setBucks] = useState(option?.reward || 0);

  useEffect(() => {
    onChange(value, bucks);
  }, [value, bucks]);

  return (
    <Box display={"flex"} gap={2} justifyContent={"space-between"} height={"29px"} mb={"10px"}>
      <Box display="flex" width={{ xs: "60%", sm: "90%" }} alignItems="center">
        <TextField
          fullWidth
          variant="standard"
          sx={{ p: 2, textAlign: "flex-end" }}
          value={value}
          placeholder={`Option ${i + 1}`}
          onChange={(e) => setValue(e.target.value)}
        />
        <Checkbox checked={checked} onClick={onSelectOption} />
      </Box>
      <Box display="flex" width={{ xs: "40%", sm: "20%" }} alignItems="center">
        <TextField
          fullWidth
          multiline={false}
          variant="standard"
          type="number"
          sx={{ p: 2, border: "none" }}
          value={bucks}
          placeholder={"0"}
          onChange={(e) => setBucks(parseInt(e.target.value))}
        />
        <PaidIcon color="primary" />
        <DeleteIcon onClick={onDelete} sx={{ cursor: "pointer", ml: 2 }} />
      </Box>
    </Box>
  );
};

const icons = {
  [TYPE_OF_INTERACTION_OPTIONS.BUTTONS]: [
    {
      id: BUTTON_TYPE.B,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#010DFF",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.A,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#FF2D3A",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.X,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#FEF200",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.Y,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#00B447",
          }}
        />
      ),
    },
  ],
  [TYPE_OF_INTERACTION_OPTIONS.DPAD]: [
    {
      id: BUTTON_TYPE.LEFT,
      component: <ArrowLeft sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.UP,
      component: <ArrowDropUp sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.RIGHT,
      component: <ArrowRight sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.DOWN,
      component: <ArrowDropDown sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
  ],
  [TYPE_OF_INTERACTION_OPTIONS.BOTH]: [
    {
      id: BUTTON_TYPE.B,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#010DFF",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.A,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#FF2D3A",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.X,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#FEF200",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.Y,
      component: (
        <span
          style={{
            minWidth: "20px",
            borderRadius: "100%",
            minHeight: "20px",
            backgroundColor: "#00B447",
          }}
        />
      ),
    },
    {
      id: BUTTON_TYPE.LEFT,
      component: <ArrowLeft sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.UP,
      component: <ArrowDropUp sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.RIGHT,
      component: <ArrowRight sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
    {
      id: BUTTON_TYPE.DOWN,
      component: <ArrowDropDown sx={{ backgroundColor: "lightgray", borderRadius: "4px" }} />,
    },
  ],
};
export default QuestionModal;
