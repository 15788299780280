import { Link, useLocation, useNavigate } from "react-router-dom";

// Material Dashboard 2 PRO React components

import * as Yup from "yup";
import { useApi } from "../../services/helpers";
// Image
import pxToRem from "../../assets/theme/functions/pxToRem";
import { ROUTES } from "../../services/constants";

import { useRef, useState } from "react";
import { runInAction } from "mobx";
import { Form, Formik } from "formik";
import FormikInput from "../../components/FormikInput";

import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { useStores } from "models";
import { useMaterialUIController, setLayout, LAYOUT_TYPES } from "../../context";
const LoginForm = () => {
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [_controller, dispatch] = useMaterialUIController();
  const { loginStore } = useStores();

  const api = useApi();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();

  const login = (data) => {
    setLoading(true);

    api.login(data.email, data.password).handle({
      onSuccess: (result) => {
        const { response } = result;
        const { user, access, refresh } = response;

        runInAction(() => {
          loginStore.setUser(user);
          loginStore.setApiToken(access, refresh);
        });
        if (user.student) {
          setLayout(dispatch, LAYOUT_TYPES.STUDENT);
        } else if (user.school_admin) {
          setLayout(dispatch, LAYOUT_TYPES.SCHOOL_ADMIN);
        } else {
          setLayout(dispatch, LAYOUT_TYPES.TEACHER);
        }

        if (queryParams.get("redirect")) navigate(queryParams.get("redirect"));
        else navigate(ROUTES.ROOT);
      },
      errorMessage: "Error signing in",
      onError: (result) => {
        console.log(result)
        //formikRef.current.setErrors(result.errors)
      },
      onFinally: () => setLoading(false),
    });
  };
  const validationSchema = Yup.object().shape({
    email: Yup.string().email().required(),
    password: Yup.string().required(),
  });

  const initialValues = {
    email: "",
    password: "",
  };
  return (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      onSubmit={(values) => login(values)}
    >
      {({ errors, isValid }) => (
        <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>
          <FormikInput name="email" label="Email" type="email" errors={errors} />
          <FormikInput name="password" label="Password" type="password" errors={errors} />
          <MDBox textAlign="right">
            <MDTypography
              component={Link}
              to={ROUTES.FORGOT_PASSWORD}
              variant="button"
              color="dark"
              fontWeight="regular"
            >
              Forgot Password?
            </MDTypography>
          </MDBox>
          <MDBox textAlign="center" mt={5}>
            <MDTypography variant="button" color="dark">
              {"Don't have an account? "}
              <MDTypography
                component={Link}
                to={ROUTES.SIGNUP}
                variant="button"
                color="primary"
                fontWeight="bold"
              >
                Register
              </MDTypography>
            </MDTypography>
          </MDBox>
          <MDBox mb={1} textAlign="center">
            <MDButton
              variant="contained"
              color="primary"
              loading={loading}
              disabled={loading || !isValid}
              sx={{ width: "-webkit-fill-available" }}
              type="submit"
            >
              Sign In
            </MDButton>
          </MDBox>
          <MDBox mt={2} textAlign="center" sx={{justifyContent: 'space-between', display: "flex", flexDirection: "row"}}>
            <MDTypography
                component={Link}
                to={"#"}
                fontSize={14}
                color="primary"
                fontWeight="bold"
                onClick={() =>
                    window.open(ROUTES.USER_PRIVACY_POLICY, "_blank", "noopener,noreferrer")
                }
            >
              Privacy Policy
            </MDTypography>
            <MDTypography
                component={Link}
                to={"#"}
                fontSize={14}
                color="primary"
                fontWeight="bold"
                onClick={() =>
                    window.open(ROUTES.USER_TERMS_AND_CONDITIONS, "_blank", "noopener,noreferrer")
                }
            >
              Terms and conditions
            </MDTypography>
          </MDBox>

        </Form>
      )}
    </Formik>
  );
};
export default LoginForm;
