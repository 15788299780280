import DataTable from "components/DataTable";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import AddStudentsModal from "components/courses/AddStudentsModal";
import {useState, useEffect} from "react";
import {useApi} from "../../services/helpers";
import {dataTableModel, renderTableRow} from "./utils";
import {PopoverActions, PopoverItem} from "../../components/PopoverActions";
import {ENROLMENT_STATUS} from "../../utils/helpers";
import {Grid} from "@mui/material";
import CourseInvitationModal from "../../components/courses/CurseInvitationModal";

const CourseStudentList = ({courseId}) => {
    const [addStudentsModal, setAddStudentsModal] = useState(false);
    const [openQRInvitationModal, setOpenQRInvitationModal] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);
    const [openPopover, setOpenPopover] = useState(false);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [datatable, setDatatable] = useState({...dataTableModel});
    const api = useApi();

    const getStudentsEnrolment = () => {
        api.getStudentsEnrolment(courseId).handle({
            onSuccess: (res) => {
                const data = res.data
                const tmp = {...dataTableModel}
                tmp.rows = data.map(e => renderTableRow(e, setAnchorEl, setOpenPopover, setSelectedItem)) || []
                setDatatable(tmp)
            },
            errorMessage: "Error getting students enrolment",
        });
    }

    const acceptStudentEnrolment = (enrolmentId) => {
        api.acceptStudentEnrolment(courseId, enrolmentId).handle({
            onSuccess: (res) => {
                setOpenPopover(false)
            },
            successMessage: "Student accepted",
            errorMessage: "Error accepting student enrolment",
        });
    }

    const denyStudentEnrolment = (enrolmentId) => {
        api.denyStudentEnrolment(courseId, enrolmentId).handle({
            onSuccess: (res) => {
                onClose()
            },
            errorMessage: "Error denying student enrolment",
        });
    }

    const onClose = () => {
        setOpenPopover(false)
        setSelectedItem(null)
    }

    useEffect(() => {
        getStudentsEnrolment(courseId);
        const interval = setInterval(() => {
            getStudentsEnrolment(courseId)
        }, 5000)

        return () => {
            clearInterval(interval)
        }
    }, [courseId, addStudentsModal, openPopover]);

    return (
        <>

            <Grid item container xs={12} display="flex" alignItems="center" mt={3} borderBottom="1px solid lightgrey"
                  pb={1} mb={3}>
                <Grid item xs={5}>
                    <MDTypography color="primary" flex={1} ml={2}>
                        Students
                    </MDTypography>
                </Grid>
                <Grid item textAlign={'right'} display='flex' flexDirection="row" justifyContent='end' xs={7}>
                    <MDBox mr={'10px'}>
                        <MDButton sx={{ml: 2}} onClick={() => setOpenQRInvitationModal(true)}>
                            Invitation code / QR
                        </MDButton>
                    </MDBox>
                    <CourseInvitationModal open={openQRInvitationModal} setOpen={setOpenQRInvitationModal} courseId={courseId}/>
                    <MDBox mr={'16px'}>
                        <MDButton sx={{ml: 2}} onClick={() => setAddStudentsModal(true)}>
                            Send Invitation
                        </MDButton>
                    </MDBox>
                    <AddStudentsModal open={addStudentsModal} setOpen={setAddStudentsModal} courseId={courseId}/>
                </Grid>
            </Grid>

            <Grid container>
                <Grid item xs={12} paddingRight={2}>
                    <DataTable
                        table={datatable}
                    />
                </Grid>
            </Grid>
            {selectedItem &&
                <PopoverActions open={openPopover} onClose={onClose} anchorEl={anchorEl} horizontal={"left"}>
                    {
                        selectedItem?.status_id === ENROLMENT_STATUS.REQUESTED && <>
                            <PopoverItem label={"Accept"} color="text"
                                         onClick={() => acceptStudentEnrolment(selectedItem.id)}/>
                            <PopoverItem
                                label={selectedItem?.status.props.id === ENROLMENT_STATUS.REQUESTED ? "Deny" : "Withdraw"}
                                color="text" onClick={() => denyStudentEnrolment(selectedItem.id)}/>
                        </>
                    }
                    {selectedItem?.status_id === ENROLMENT_STATUS.INVITED &&
                        <PopoverItem label={"Withdraw"} color="text"
                                     onClick={() => denyStudentEnrolment(selectedItem.id)}/>}

                    {selectedItem?.status_id === ENROLMENT_STATUS.ACCEPTED &&
                        <PopoverItem label={"Remove"} color="text"
                                     onClick={() => denyStudentEnrolment(selectedItem.id)}/>
                    }
                </PopoverActions>
      }
        </>
    );
};

export default CourseStudentList;
