import React, { useEffect, useMemo, useRef, useState } from "react";
import * as Yup from "yup";

import { AddCircleOutline } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import { Form, Formik, useFormikContext } from "formik";
import moment from "moment";
import { CalendarCrudModal } from "../../components/CalendarCRUDModal";
import MDBox from "../../components/MDBox";
import ModalItem from "../../components/ModalItem";
import { useApi } from "../../services/helpers";
import NewLessonModal from "../lessons/NewLessonModal";
import {
  DAYS_OF_WEEK,
  REPEAT_OPTIONS_LIST,
  dateValidator,
  minMaxTimeValidator,
  updateTimeValidator,
} from "./utils";


export const CourseModal = ({
  handleClose,
  showModal,
  setShowModal,
  onSave,
  selectedMeeting,
  deleteCourseMeeting,
  setRepeatOptions,
  repeatOptions,
  courseId,
  openModal,
}) => {
  const api = useApi();
  const formikRef = useRef();
  const [loading, setLoading] = useState(false);
  const [newLessonModal, setNewLessonModal] = useState(false);
  const [onNewLessonCreated, setOnNewLessonCreated] = useState(false);
  const [courses, setCourses] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [courseSelected, setCourseSelected] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [repeat, setRepeat] = useState("daily");

  const validationSchema = Yup.object().shape({
    date: Yup.string().required(),
    start_time: Yup.string().required(),
    end_time: Yup.string().required(),
    title: Yup.string().required('Topic is a required field'),
    course: Yup.mixed().required(),
    lesson: Yup.mixed(),
    period: Yup.string(),
    repeat_config: Yup.mixed(),
  });

  const initialValues = (data) => {
    return {
      id: data?.id || "",
      date: data?.date || "",
      course: data?.course ? data?.course || "" : courseSelected,
      start_time: data?.start || "",
      end_time: data?.end || "",
      title: data?.title || "",
      lesson: data?.lesson || "",
      period: data?.period || "",
      repeat_config: data?.repeat_config || null,
    };
  };

  const getCourses = (data) => {
    setLoading(true);
    api.getCourses(data).handle({
      onSuccess: (result) => {
        const { data } = result;
        setCourses(data);
        setCourseSelected(data.find((course) => `${course.id}` === `${courseId}`));
        if (openModal) {
          setShowModal(true);
        }
      },
      errorMessage: "Error getting courses",
      onFinally: () => setLoading(false),
    });
  };

  const getLessons = (data, id) => {
    setLoading(true);
    api.getLessons(data, id).handle({
      onSuccess: (result) => {
        const {
          data: { results },
        } = result;
        setLessons(results);
      },
      errorMessage: "Error getting courses",
      onFinally: () => setLoading(false),
    });
  };

  const createCourseMeeting = (data) => {
    setLoading(true);
    const validatedData = {
      ...data,
      date: dateValidator(data.date),
      start_time: updateTimeValidator(data.start_time),
      end_time: updateTimeValidator(data.end_time),
    };
    api.createCourseMeeting(validatedData).handle({
      onSuccess: () => {
        onSave();
      },
      errorMessage: "Error creating course meeting",
      successMessage: "Course meeting created successfully!",
      onFinally: () => setLoading(false),
    });
  };

  const updateCourseMeeting = (data) => {
    setLoading(true);
    const validatedData = {
      ...data,
      start_time: updateTimeValidator(data.start_time),
      end_time: updateTimeValidator(data.end_time),
    };
    api.updateCourseMeeting(validatedData).handle({
      onSuccess: () => {
        onSave();
      },
      errorMessage: "Error updating course meeting",
      successMessage: "Course meeting updated successfully!",
      onFinally: () => setLoading(false),
    });
  };

  function repeatGetter(values) {
    return values.repeat_config;
  }

  useEffect(() => {
    getCourses();
  }, []);

  useEffect(() => {
    if (courseSelected) {
      getLessons(null, courseSelected?.id);
    }
  }, [courseSelected, onNewLessonCreated, newLessonModal]);

  useEffect(() => {
    if (repeatOptions === false) {
      formikRef.current?.setFieldValue("repeat_config", null);
    }
  }, [repeatOptions]);

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height={{ xs: "90vw", md: "75vh" }}
      width={{ xs: "80vw", md: "700px" }}
      title={selectedMeeting?.id ? "Edit Class Meeting" : "Add Class Meeting"}
      handleClose={() => {
        handleClose()
        setRepeatOptions(false)
      }}
    >
      <Formik
        innerRef={formikRef}
        initialValues={initialValues(selectedMeeting ? selectedMeeting : null)}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={false}
        onSubmit={(values) =>
          selectedMeeting?.id ? updateCourseMeeting(values) : createCourseMeeting(values)
        }
      >
        {({ values, setFieldValue, isValid, errors }) => {
          return (
            <Form style={{ display: "flex", flexDirection: "column", flex: 1 }}>
              <Grid container>
                <Grid item xs={12} pb={1}>
                  <FormikInput
                    type="date"
                    name="date"
                    setFieldValue={setFieldValue}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6} pr={1} pb={2}>
                  <MDTypography color="dark" fontSize={{ xs: "20px", md: "24px" }}>
                    From Time
                  </MDTypography>
                  <FormikInput
                    type="time"
                    name="start_time"
                    value={values.start_time}
                    extraParams={{
                      maxTime: minMaxTimeValidator(values.end_time),
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={6} pl={1} pb={2}>
                  <MDTypography color="dark" fontSize={{ xs: "20px", md: "24px" }}>
                    To Time
                  </MDTypography>
                  <FormikInput
                    type="time"
                    value={values.end_time}
                    name="end_time"
                    extraParams={{
                      minTime: minMaxTimeValidator(values.start_time),
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item xs={3} alignItems="center">
                  <MDTypography
                    color="dark"
                    fontSize={{ xs: "20px", md: "24px" }}
                    flexDirection="row"
                    mr={2}
                  >
                    Course
                  </MDTypography>
                </Grid>
                <Grid item xs={9}>
                  <FormikInput
                    type="select"
                    name="course"
                    placeholder="course"
                    initialValue={values.course}
                    readOnly={Boolean(courseId)}
                    options={courses}
                    onChangeSelect={(v, e) => {
                      setCourseSelected(e);
                    }}
                    errors={errors}
                  />
                </Grid>
                <Grid item container xs={12} justifyContent={"space-between"} alignItems="center">
                  <Grid item container xs={4} sm={3} alignItems="center" wrap="nowrap">
                    <MDTypography
                      color="dark"
                      fontSize={{ xs: "20px", md: "24px" }}
                      flexDirection="row"
                      mr={2}
                    >
                      Lesson
                    </MDTypography>
                    <IconButton
                      sx={{ paddingLeft: 0.5, height: "40px" }}
                      color="primary"
                      onClick={() => (courseSelected ? setNewLessonModal(true) : null)}
                    >
                      <AddCircleOutline />
                    </IconButton>
                  </Grid>
                  <Grid item xs={7} sm={9}>
                    <Box flex={1}>
                      <FormikInput
                        type="select"
                        name="lesson"
                        initialValue={values.lesson}
                        placeholder="Lesson"
                        errors={errors}
                        options={lessons}
                        onChangeSelect={(v, e) => {
                          // console.log('curr titl', values.title, 'prev topic from le', values.lesson?.description, 'nw tpopic', e?.description);
                          if(values.title === '' || values.title === undefined || values.lesson.description === values.title) {
                            // console.log('setting title');
                            setFieldValue("title", values.lesson?.description)
                          }
                        }}
                      />
                    </Box>
                  </Grid>
                </Grid>
                <Grid item xs={3} alignItems="center">
                  <MDTypography
                    color="dark"
                    fontSize={{ xs: "20px", md: "24px" }}
                    flexDirection="row"
                    mr={2}
                  >
                    Topic
                  </MDTypography>
                </Grid>
                <Grid item xs={9}>
                   {/*TODO update this field when lesson changes ( current code doesnt work) */}
                  <FormikInput name="title" placeholder="Topic" errors={errors} />
                </Grid>
                <Grid item xs={3} alignItems="center">
                  <MDTypography
                    color="dark"
                    fontSize={{ xs: "20px", md: "24px" }}
                    flexDirection="row"
                    mr={2}
                  >
                    Period
                  </MDTypography>
                </Grid>
                <Grid item xs={9}>
                  <FormikInput name="period" placeholder="Period" errors={errors} />
                </Grid>

                <Grid item xs={12}>
                  <MDTypography
                    color="dark"
                    fontSize={{ xs: "20px", md: "24px" }}
                    flexDirection="row"
                    mr={2}
                  >
                    Repeat{" "}
                    <Checkbox
                      checked={repeatOptions}
                      onChange={() => {
                        setRepeatOptions(!repeatOptions);
                      }}
                    />
                  </MDTypography>
                  {repeatOptions && (
                    <PeriodSelector
                      values={repeatGetter(values)}
                      setFieldValue={setFieldValue}
                      repeat={repeat}
                      setRepeat={setRepeat}
                    />
                  )}
                </Grid>
                <Grid item xs={12} container justifyContent="center" gap={2}>
                  {selectedMeeting?.id && (
                    <MDButton
                      variant="outlined"
                      disabled={!isValid || loading}
                      sx={{ px: 5, minWidth: 110 }}
                      color="error"
                      onClick={() => setConfirmationModal(true)}
                    >
                      Delete
                    </MDButton>
                  )}
                  {selectedMeeting?.id && <MDButton>Copy Meeting</MDButton>}
                  <Button
                    variant="filled"
                    sx={{ px: 5, maxWidth: 100, boxShadow: 1 }}
                    color="secondary"
                    onClick={() => setShowModal(false)}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    disabled={!isValid || loading}
                    sx={{ px: 5, minWidth: 110 }}
                    color="primary"
                    onClick={() => {
                      formikRef?.current?.handleSubmit();
                      setShowModal(false);
                      onSave();
                    }}
                  >
                    {selectedMeeting?.id ? "Edit" : "Add"}
                  </Button>
                </Grid>
              </Grid>
              <NewLessonModal
                showModal={newLessonModal}
                courseSelected={courseSelected}
                onCreated={() => setOnNewLessonCreated(true)}
                handleClose={() => setNewLessonModal(false)}
              />
              <CalendarCrudModal
                open={confirmationModal}
                handleClose={() => setConfirmationModal(false)}
                title={"Delete Class Meeting"}
                subtitle={"Are you sure you want to delete this event?"}
                confirmAllText={selectedMeeting?.repeat_config ? "Delete series" : ""}
                confirmText={"Delete one"}
                handleConfirmAll={
                  selectedMeeting?.repeat_config
                    ? () => {
                        deleteCourseMeeting(selectedMeeting?.course.id, selectedMeeting?.id, {
                          all: true,
                        });
                        setConfirmationModal(false);
                        setShowModal(false);
                      }
                    : null
                }
                handleConfirm={() => {
                  deleteCourseMeeting(selectedMeeting?.course?.id, selectedMeeting?.id);
                  setConfirmationModal(false);
                  setShowModal(false);
                }}
              />
            </Form>
          );
        }}
      </Formik>
    </ModalItem>
  );
};

const PeriodSelector = ({ values, setFieldValue, repeat, setRepeat }) => {
  const { errors } = useFormikContext();
  const [monthRadioSelected, setMonthRadioSelected] = useState(
    values?.monthly?.day > 0 ? "REPEAT" : "ON"
  );
  const monthlyDay = values?.monthly?.day > 0 && monthRadioSelected === "REPEAT";

  const dailyOptions = useMemo(
    () => (
      <RadioGroup
        defaultValue="daily"
        onChange={(e) => {
          setFieldValue(
            "repeat_config.daily",
            e.target.value === "workdays"
              ? { space_days: 1, only_workdays: true }
              : { space_days: values?.daily?.space_days }
          );
          setMonthRadioSelected(e.target.value);
        }}
      >
        <FormControlLabel
          value="daily"
          control={<Radio checked={!values?.daily?.only_workdays} />}
          label={
            <MDBox sx={{ display: "flex", alignItems: "center" }}>
              Every{" "}
              <TextField
                sx={{ mx: 1, width: 70 }}
                type={"number"}
                disabled={values?.daily?.only_workdays}
                value={values?.daily?.space_days}
                onChange={(e) => {
                  setFieldValue("repeat_config.daily.space_days", parseInt(e.target.value));
                }}
                variant="standard"
              />
              days
            </MDBox>
          }
        />
        <FormControlLabel
          value="workdays"
          control={<Radio checked={values?.daily?.only_workdays} />}
          label="Every workdays"
        />
      </RadioGroup>
    ),
    [values, setFieldValue]
  );
  const weeklyOptions = useMemo(
    () => (
      <>
        <Box display="flex" alignItems="center">
          <MDTypography color="dark" variant="body2">
            Repeat every
          </MDTypography>
          <TextField
            type={"number"}
            sx={{ mx: 1, width: 70 }}
            value={values?.weekly?.space_weeks}
            onChange={(e) => {
              setFieldValue("repeat_config.weekly.space_weeks", parseInt(e.target.value));
            }}
            variant="standard"
          />
          <MDTypography color="dark" variant="body2">
            week(s) on the following day(s)
          </MDTypography>
        </Box>
        <Box>
          <FormGroup>
            <Box display="flex" flexDirection="row" flexWrap="wrap">
              {DAYS_OF_WEEK.map((day) => (
                <FormControlLabel
                  key={day.id}
                  control={
                    <Checkbox
                      checked={!!values?.weekly?.weekdays?.includes(day?.id)}
                      onChange={(e, value) => {
                        let data = [...(values?.weekly?.weekdays || []), day.id];
                        if (!value) data = data.filter((d) => d !== day.id);
                        setFieldValue("repeat_config.weekly.weekdays", data);
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                    />
                  }
                  label={day?.day}
                />
              ))}
            </Box>
          </FormGroup>
        </Box>
      </>
    ),
    [values, setFieldValue]
  );
  const biweeklyOptions = useMemo(
    () => (
      <Box>
        <FormGroup>
          <Box display="flex" flexDirection="row" flexWrap="wrap">
            {DAYS_OF_WEEK.map((day) => (
              <FormControlLabel
                key={day.id}
                control={
                  <Checkbox
                    checked={!!values?.biweekly?.weekdays.includes(day?.id)}
                    onChange={(e, value) => {
                      let data = [...(values?.biweekly?.weekdays || []), day.id];
                      if (!value) data = data.filter((d) => d !== day.id);
                      setFieldValue("repeat_config.biweekly.weekdays", data);
                    }}
                    inputProps={{ "aria-label": "controlled" }}
                  />
                }
                label={day.day}
              />
            ))}
          </Box>
        </FormGroup>
      </Box>
    ),
    [values, setFieldValue]
  );

  const getNthWeekday = (values) => {
    const weekday = values?.monthly?.nth_weekday?.[1];
    const dayObject = DAYS_OF_WEEK.filter((day) => day.id === weekday)?.[0];
    return dayObject
      ? {
          value: dayObject.id,
          name: dayObject.day,
        }
      : null;
  };
  const monthlyOptions = useMemo(
    () => (
      <RadioGroup
        defaultValue={"REPEAT"}
        onChange={(e) => {
          setFieldValue(
            `repeat_config.monthly`,
            e.target.value === "ON"
              ? { nth_weekday: [0, null], space_months: 0 }
              : { day: 0, space_months: 0 }
          );
          setMonthRadioSelected(e.target.value);
        }}
      >
        <FormControlLabel
          value="REPEAT"
          control={<Radio checked={monthRadioSelected === "REPEAT"} />}
          label={
            <MDBox display={"flex"} alignItems={"center"}>
              Repeat
              <TextField
                sx={{ mx: 1, width: 70 }}
                type={"number"}
                disabled={monthRadioSelected === "ON"}
                value={values?.monthly?.day}
                onChange={(e) =>
                  setFieldValue("repeat_config.monthly.day", parseInt(e.target.value))
                }
                variant="standard"
              />
              days every
              <TextField
                sx={{ mx: 1, width: 70 }}
                type={"number"}
                disabled={monthRadioSelected === "ON"}
                value={monthlyDay ? values?.monthly?.space_months : 0}
                onChange={(e) =>
                  setFieldValue("repeat_config.monthly.space_months", parseInt(e.target.value))
                }
                variant="standard"
              />
              months
            </MDBox>
          }
        />
        <FormControlLabel
          value="ON"
          control={<Radio checked={monthRadioSelected === "ON"} />}
          label={
            <MDBox display={"flex"} alignItems={"center"}>
              on{" "}
              <TextField
                sx={{ mx: 1, width: 70 }}
                type={"number"}
                value={values?.monthly?.nth_weekday?.[0]}
                disabled={monthRadioSelected === "REPEAT"}
                onChange={(e) =>
                  setFieldValue("repeat_config.monthly.nth_weekday[0]", parseInt(e.target.value))
                }
                variant="standard"
              />
              <FormikInput
                type="select"
                options={DAYS_OF_WEEK.map((day) => ({ value: day.id, name: day.day }))}
                name="on"
                disabled={monthRadioSelected === "REPEAT"}
                value={getNthWeekday(values)}
                onChangeSelect={(e, value) =>
                  setFieldValue("repeat_config.monthly.nth_weekday[1]", parseInt(value?.value))
                }
                errors={errors}
                sx={{ mx: 1, width: "150px" }}
              />{" "}
              every
              <TextField
                sx={{ mx: 1, width: 60 }}
                type={"number"}
                disabled={monthRadioSelected === "REPEAT"}
                value={!monthlyDay ? values?.monthly?.space_months : 0}
                onChange={(e) =>
                  setFieldValue("repeat_config.monthly.space_months", parseInt(e.target.value))
                }
                variant="standard"
              />
              months
            </MDBox>
          }
        />
      </RadioGroup>
    ),
    [values, setFieldValue, errors]
  );

  useEffect(() => {
    if (values) {
      setRepeat(Object.keys(values)[0]);
    }

    if (typeof values?.end === "number") {
      setFieldValue("repeat_config.endType", "afterXOcurrences");
    } else if (typeof values?.end === "string") {
      setFieldValue("repeat_config.endType", "afterDate");
    } else {
      setFieldValue("repeat_config.endType", "noEndDate");
    }
  }, []);

  return (
    <>
      <Box display="flex">
        <Box>
          <RadioGroup
            sx={{ px: 1, borderRight: "1px solid lightgray" }}
            onChange={() => setFieldValue("repeat_config", null)}
          >
            {REPEAT_OPTIONS_LIST.map((option) => {
              return (
                <FormControlLabel
                  key={option.key}
                  value={option.key}
                  control={<Radio checked={option.key === repeat} />}
                  label={option.label}
                  onClick={() => {
                    setRepeat(option.key);
                  }}
                />
              );
            })}
          </RadioGroup>
        </Box>
        <Box px={1} display="flex" flexDirection="column" flex={1}>
          {repeat === "daily"
            ? dailyOptions
            : repeat === "weekly"
            ? weeklyOptions
            : repeat === "biweekly"
            ? biweeklyOptions
            : repeat === "monthly"
            ? monthlyOptions
            : null}
        </Box>
      </Box>
      <Box px={1} pt={1} mb={3} borderTop="1px solid lightgray">
        <RadioGroup row>
          <FormControlLabel
            value="noEndDate"
            checked={values?.endType === "noEndDate"}
            onChange={() => {
              setFieldValue("repeat_config.endType", "noEndDate");
              setFieldValue("repeat_config.end", null);
            }}
            control={<Radio />}
            label="No end date"
          />
          <FormControlLabel
            value="afterXOcurrences"
            checked={values?.endType === "afterXOcurrences"}
            onChange={() => {
              setFieldValue("repeat_config.endType", "afterXOcurrences");
              setFieldValue("repeat_config.end", 1);
            }}
            control={<Radio />}
            label={
              <MDBox sx={{ display: "flex", alignItems: "center" }}>
                After
                <TextField
                  sx={{ mx: 1, width: 60 }}
                  type={"number"}
                  value={values?.end}
                  onChange={(e) => setFieldValue(`repeat_config.end`, parseInt(e.target.value))}
                  variant="standard"
                />
                ocurrences
              </MDBox>
            }
          />
          <FormControlLabel
            value="afterDate"
            checked={values?.endType === "afterDate"}
            onChange={() => {
              setFieldValue("repeat_config.endType", "afterDate");
              setFieldValue(`repeat_config.end`, moment().format("YYYY-MM-DD"));
            }}
            control={<Radio />}
            label={
              <MDBox style={{ display: "flex", gap: "16px", alignItems: "center" }}>
                {"End by  "}
                <FormikInput
                  type="date"
                  name={values?.endType === "afterDate" ? `repeat_config.end` : "nada"}
                  errors={errors}
                />
              </MDBox>
            }
          />
        </RadioGroup>
      </Box>
    </>
  );
};
