import React from 'react';

const ProgressPieChartIcon = ({ percent, text }) => {
    // Ensure percent is within 0 - 100 range
    const normalizedPercent = Math.min(Math.max(percent, 0), 100);

    // Calculate the stroke offset based on the percentage
    const radius = 14; // Radius of the circle for a 32px icon with some padding
    const circumference = 2 * Math.PI * radius;
    const offset = circumference - (normalizedPercent / 100) * circumference;

    return (
        <svg width="32" height="32" viewBox="0 0 32 32">
            <circle
                cx="16" // Center x-coordinate
                cy="16" // Center y-coordinate
                r={radius}
                fill="none"
                stroke="#e6e6e6" // Background circle color
                strokeWidth="4"
            />
            <circle
                cx="16"
                cy="16"
                r={radius}
                fill="none"
                stroke="#4caf50" // Progress color
                strokeWidth="4"
                strokeDasharray={circumference}
                strokeDashoffset={offset}
                strokeLinecap="round"
                transform="rotate(-90 16 16)" // Rotate to start from the top
            />
            <text
                x="50%"
                y="50%"
                textAnchor="middle"
                dy=".3em"
                fontSize="10"
                fill="#333"
            >
                {text}
            </text>
        </svg>
    );
};

export default ProgressPieChartIcon;
