import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { CollapsableCard } from "components/CollapsableCard";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import MDInput from "../../components/MDInput";
import { useApi } from "../../services/helpers";
import { StudentAnswersModal } from "./studentAnswersModal";
import Checkbox from "@mui/material/Checkbox";
import {
  MODALITY_OPTIONS,
  TYPE_OF_MOMENT_POPUP,
  TYPE_OF_QUESTION_ABOUT,
  TYPE_OF_QUESTIONNAIRE_OPTIONS,
} from "../../utils/helpers";
import useTabs from "../../utils/useTabs";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

const QuestionTitleComponent = ({ question, courseId, onCheched }) => {
  const [isQuestionChecked, setIsQuestionChecked] = React.useState(
    question?.courses.length > 0 && !!question?.courses.find((id) => id === +courseId)
  );
  // console.log("question is:", question);
  return (
    <MDBox display={"flex"} justifyContent={"space-between"}>
      <MDBox display={"flex"} flexDirection={"column"}>
        <MDBox display={"flex"} alignItems={"center"}>
          <MDTypography variant="h5" fontWeight={"bold"} color="primary">
            {question?.prompt}
          </MDTypography>
          <Checkbox
            checked={isQuestionChecked}
            onChange={() => {
              setIsQuestionChecked(!isQuestionChecked);
              onCheched(!isQuestionChecked, question?.id);
            }}
          />
        </MDBox>
        <MDTypography variant="subtitle2" color="secondary" fontWeight="regular">
          Question type: {TYPE_OF_QUESTIONNAIRE_OPTIONS._LABELS[question?.question_type]}
        </MDTypography>
        <MDTypography variant="subtitle2" color="secondary" fontWeight="regular">
          Question about: {TYPE_OF_QUESTION_ABOUT._LABELS[question?.question_about]}
        </MDTypography>
        <MDTypography variant="subtitle2" color="secondary" fontWeight="regular">
          Show question in: {TYPE_OF_MOMENT_POPUP._LABELS[question?.question_pop_up]}
        </MDTypography>
      </MDBox>
    </MDBox>
  );
};

const initiFilterParams = {
  search: "",
  page: 1,
  page_size: 50,
};

const TeacherCourseWBQBank = () => {
  const { courseId } = useParams();
  const api = useApi();
  const [showAnswersModal, setShowAnswersModal] = React.useState(false);
  const [questions, setQuestions] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = React.useState({});
  const [filterParams, setFilterParams] = React.useState(initiFilterParams);
  const { search, from_date: fromDate, to_date: toDate, page, page_size } = filterParams;
  const { tabValue, handleTabValueChange, CustomTabPanel, a11yProps } = useTabs()

  const getWBQuestions = (filterParams) => {
    api.getWBQuestions(filterParams).handle({
      onSuccess: (res) => {
        setQuestions(res.data);
      },
      errorMessage: "Error getting questions",
    });
  };

  const addWBQuestionInCourse = (questionId) => {
    api.addWBQuestionInCourse(questionId, { course: courseId }).handle({
      onSuccess: (res) => {
        setQuestions(res.data.results);
      },
      successMessage: "Added to this course",
      errorMessage: "Error adding to the course",
    });
  };

  const removeWBQuestionFromCourse = (questionId) => {
    api.removeWBQuestionFromCourse(questionId, { course: courseId }).handle({
      onSuccess: (res) => {
        setQuestions(res.data.results);
      },
      successMessage: "Removed from this course",
      errorMessage: "Error removing from the course",
    });
  };

  const onQuestionCheck = (value, questionId) => {
    value ? addWBQuestionInCourse(questionId) : removeWBQuestionFromCourse(questionId);
  };

  const setFilter = (field, value) => {
    setFilterParams({ ...filterParams, [field]: value });
  };

  // const onCollapse = (meetingId) => (isCollapsed) => {
  //   if (!isCollapsed) {
  //     // getStats(meetingId)
  //   }
  // }

  React.useEffect(() => {
    getWBQuestions(filterParams);
  }, [filterParams]);

  const renderQuestionList = (questions) => {
    return questions?.map((question, midx) => (
        <CollapsableCard
            key={`meeting-${question.id}`}
            TitleComponent={
              <QuestionTitleComponent
                  question={question}
                  courseId={courseId}
                  onCheched={onQuestionCheck}
              />
            }
            // onCollapse={onCollapse(question.id)}
        >
          <MDTypography variant="subtitle2" color="secondary" fontWeight="medium">
            Possible Answers:
          </MDTypography>
          {question?.answers?.map((answer, index) => (
              <MDTypography key={index} variant="subtitle2" color="secondary" fontWeight="medium">
                {answer?.answer}
              </MDTypography>
          ))}
        </CollapsableCard>
    ))
  }

  return (
    <MDBox width={"100%"}>
      <MDBox py={1} gap={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography flex={1} variant="h3" color="primary" fontWeight="regular">
          Student feedback
        </MDTypography>
      </MDBox>
      <Grid container mt={4}>
        {/* FILTERS */}
        <Box
          item
          xs={12}
          width={"100%"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"space-between"}
          gap={2}
        >
          <Box width={{ xs: "100%", md: "30%" }}>
            <MDInput
              placeholder="Search..."
              size="small"
              fullWidth
              sx={{ marginBottom: 0 }}
              value={search}
              onChange={({ currentTarget }) => {
                setFilter("search", currentTarget.value);
              }}
            />
          </Box>
        </Box>
        {/* LIST */}
        <Grid item xs={12} mt={4}>
          <Box sx={{ width: '100%', marginTop: 1 }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={tabValue} onChange={handleTabValueChange} aria-label="Lesson Tabs">
                <Tab label="Check-in" {...a11yProps(0)} />
                <Tab label="Exit Ticket" {...a11yProps(1)} />
              </Tabs>
            </Box>
            {[1,2].map(pop_up => {
                return (
                    <CustomTabPanel value={tabValue} index={pop_up - 1}>
                    {renderQuestionList(questions?.filter((question) => question.question_pop_up === pop_up) || [])}
                    </CustomTabPanel>
                )
            })}
          </Box>
        </Grid>
      </Grid>
      {/*  */}
      <StudentAnswersModal
        question={selectedQuestion}
        showModal={showAnswersModal}
        setShowModal={setShowAnswersModal}
      />
    </MDBox>
  );
};

export default TeacherCourseWBQBank;
