/**
 =========================================================
 * Material Dashboard 2 PRO React - v2.1.0
 =========================================================

 * Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
 * Copyright 2022 Creative Tim (https://www.creative-tim.com)

 Coded by www.creative-tim.com

 =========================================================

 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 */

import {useState, useEffect} from "react";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
// react-router components
import {Routes, Route, useLocation} from "react-router-dom";

// @mui material components
import {ThemeProvider} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Material Dashboard 2 PRO React examples
import Sidenav from "components/Sidenav";

// Material Dashboard 2 PRO React themes
import theme from "assets/theme";

// Material Dashboard 2 PRO React Dark Mode themes
import themeDark from "assets/theme-dark";

// Material Dashboard 2 PRO React routes
import routes from "routes";

// Material Dashboard 2 PRO React contexts
import {useMaterialUIController} from "context";

// Images
import brandWhite from "assets/images/logo-ct.png";
import brandDark from "assets/images/logo-ct-dark.png";
import {setupRootStore} from "./models";
import icon from "./assets/icons/favicon.png";
import {ROUTES} from "./services/constants";

// Pages
import SignIn from "./pages/sign-in";
import SignUp from "./pages/sign-up";
import NotFound from "./pages/not-found";
import ForgotPassword from "./pages/forgot-password";
import Profile from "./pages/profile";
import NewCourse from "./pages/new-course";
import Logout from "pages/logout";
import {LAYOUT_TYPES} from "context";
import CourseDetail from "pages/course-detail";
import CourseLessons from "pages/lessons";
import AdminPanel from "pages/admin-panel";
import EarningHistory from "pages/earning-history";
import PrivacyPolicy from "pages/privacy-policy";
import TermsAndConditions from "pages/terms-and-conditions";
import GameView from "pages/game-view";
import Presentation from "pages/presentation";
import Questionnaire from "pages/questionnaire";
import Meeting from "./pages/meeting";
import {RealtimeContextProvider} from "./services/streaming/provider";
import MeetingDisplay from "./pages/meeting/meeting_display";
import MeetingEnter from "./pages/meeting/meeting_enter";
import {QrReaderTest} from "./pages/qr-reader";
import {Mixpanel, setupMixpanelOnRootStore} from "./services/Mixpanel";
import SetNewPassword from "./pages/set-new-password";
import {WakeLockContextProvider} from "./services/wakelock_service";
import ManageProduct from "./pages/manage-product";
import ProductDetailIndex from "./pages/product-detail";


export default function App() {
  const [controller] = useMaterialUIController();
  const {direction, layout, sidenavColor, transparentSidenav, whiteSidenav, darkMode} =
    controller;
  const {pathname} = useLocation();
  const [rootStore, setRootStore] = useState(undefined);

  useEffect(() => {
    const favicon = document.getElementById("favicon");
    favicon.setAttribute("href", icon);
  }, []);

  useEffect(() => {
    (async () => {
      setupRootStore().then((rootStore) => {
        setRootStore(rootStore);
        setupMixpanelOnRootStore(rootStore);
        Mixpanel.track("App loaded")
      });
    })();
  }, []);

  useEffect(() => {
    if (rootStore) {
      rootStore.loginStore.setUp();
    }
  }, [rootStore]);

  //Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return <Route exact path={route.route} element={route.component} key={route.key}/>;
      }

      return null;
    });

  return (
    <RealtimeContextProvider>

      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ThemeProvider theme={darkMode ? themeDark : theme}>
          <CssBaseline/>
          {[LAYOUT_TYPES.TEACHER, LAYOUT_TYPES.STUDENT, LAYOUT_TYPES.SCHOOL_ADMIN].includes(layout) && (
            <Sidenav
              color={sidenavColor}
              brand={(transparentSidenav && !darkMode) || whiteSidenav ? brandDark : brandWhite}
              brandName=""
              routes={routes.filter(route => {
                if (layout === LAYOUT_TYPES.TEACHER) return route.teacher === true
                else if (layout === LAYOUT_TYPES.STUDENT) return route.student === true
                else if (layout === LAYOUT_TYPES.SCHOOL_ADMIN) return route.school_admin === true
                return false
              })}
            />
          )}
          <Routes>
            {getRoutes(routes)}
            <Route path={ROUTES.LOGIN} element={<SignIn/>}/>
            <Route path={ROUTES.SIGNUP} element={<SignUp/>}/>
            <Route path={ROUTES.LOGOUT} element={<Logout/>}/>
            <Route path={ROUTES.NEW_COURSE} element={<NewCourse/>}/>
            {/*<Route path={`${ROUTES.COURSES}/:courseId`} element={<CourseDetail />} />*/}
            <Route path={`${ROUTES.COURSES}/:courseId/:section`} element={<CourseDetail/>}/>
            <Route path={`${ROUTES.COURSES}/:courseId/lessons`} element={<CourseLessons/>}/>
            <Route
              path={`${ROUTES.COURSES}/:courseId/presentations/:presentationId`}
              element={<Presentation/>}
            />
            <Route
              path={`${ROUTES.COURSES}/:courseId/questionnaires/:questionnaireId`}
              element={<Questionnaire/>}
            />
            <Route path={`${ROUTES.COURSES}/:courseId/admin/meeting/:meetingId`} element={<AdminPanel/>}/>
            <Route path={ROUTES.PRODUCT_MANAGEMENT(':productId')} element={<ManageProduct/>}/>
            <Route path={ROUTES.CREATE_NEW_PRODUCT} element={<ManageProduct/>}/>
            <Route path={ROUTES.PRODUCT_DETAIL(':productId')} element={<ProductDetailIndex/>}/>
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPassword/>}/>
            <Route path={ROUTES.SET_NEW_PASSWORD} element={<SetNewPassword/>}/>
            <Route path={ROUTES.PROFILE} element={<Profile/>}/>
            <Route path={ROUTES.EARNING_HISTORY} element={<EarningHistory/>}/>
            <Route path={ROUTES.USER_PRIVACY_POLICY} element={<PrivacyPolicy/>}/>
            <Route path={ROUTES.USER_TERMS_AND_CONDITIONS} element={<TermsAndConditions/>}/>
            <Route path={ROUTES.GAME_VIEW(':meetingId')} element={
              <WakeLockContextProvider>
                <GameView/>
              </WakeLockContextProvider>
            }/>
            <Route path={ROUTES.QR_READER} element={<QrReaderTest/>}/>

            <Route path="/meeting" element={<Meeting/>}/>
            <Route path="/meeting/:meetingId/display" element={<MeetingDisplay/>}/>

            {/*<Route path="/meeting/enter" element={<MeetingEnter />} />*/}

            <Route path="404" element={<NotFound/>}/>
            <Route path="*" element={<NotFound/>}/>
          </Routes>
        </ThemeProvider>
      </LocalizationProvider>

    </RealtimeContextProvider>
  );
}
