import React from "react";
import PageLayout from "components/PageLayout";
import MarketplaceHome from "./MarketplaceHome";

const Marketplace = () => {
  return (
    <PageLayout
      teacherComponent={<MarketplaceHome />}
      studentComponent={<MarketplaceHome />}
      schoolAdminComponent={<MarketplaceHome />}
    />
  );
};

export default Marketplace;
