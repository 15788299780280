import { Instance, SnapshotOut, types } from "mobx-state-tree";
import { withEnvironment } from "../extensions/with-environment";
import { withRootStore } from "../extensions/with-root-store";

/**
 * Model description here for TypeScript hints.
 */
const Language = types
  .model("Languages")
  .props({ id: types.number, label: types.string, value: types.string });

const TeacherModel = types.model("Teacher").props({
  id: types.number,
  image: types.maybeNull(types.string),
  grades: types.string,
  school: types.string,
});

const StudentModel = types.model("Teacher").props({
  id: types.number,
  image: types.maybeNull(types.string),
  grade: types.string,
  school: types.string,
  balance: types.number,
});

const SchoolAdminModel = types.model("SchoolAdmin").props({
  id: types.number,
  logo: types.maybeNull(types.string),
  name: types.string,
});

export const LoginStoreModel = types
  .model("LoginStore")
  .extend(withRootStore)
  .extend(withEnvironment)
  .props({
    id: types.maybeNull(types.number),
    title: types.maybeNull(types.string),
    name: types.maybeNull(types.string),
    is_admin: types.maybeNull(types.boolean),
    email: types.maybeNull(types.string),
    phone_number: types.maybeNull(types.string),
    access_token: types.maybeNull(types.string),
    refresh_token: types.maybeNull(types.string),
    languages: types.maybeNull(types.array(Language)),
    teacher: types.maybeNull(TeacherModel),
    student: types.maybeNull(StudentModel),
    school_admin: types.maybeNull(SchoolAdminModel),
  })
  .views((self) => ({
    get isLoggedIn() {
      return self.access_token !== null && self.access_token !== undefined;
    },
  }))
  .actions((self) => ({
    setApiToken(token: string | null, refresh?: string) {
      const api = self.environment.api.apisauce;
      self.access_token = token;
      if (refresh) self.refresh_token = refresh;
      if (token) {
        api?.setHeader("Authorization", "Bearer " + token);
      } else {
        api?.deleteHeader("Authorization");
      }
    },
    setLanguages(languages: any) {
      self.languages = languages;
    },
    setUp() {
      if (self.access_token) {
        self.environment.api.apisauce?.setHeader("Authorization", "Bearer " + self.access_token);
      } else {
        self.environment.api.apisauce?.deleteHeader("Authorization");
      }
    },
    setUser(user: any) {
      self.id = user.id;
      self.title = user.title;
      self.name = user.name;
      self.email = user.email;
      self.phone_number = user.phone_number;
      self.is_admin = user.is_admin;
      if (user.teacher) self.teacher = TeacherModel.create(user.teacher);
      if (user.student) self.student = StudentModel.create(user.student);
      if (user.school_admin) self.school_admin = SchoolAdminModel.create(user.school_admin);
    },
    reset() {
      const api = self.environment.api.apisauce;
      api?.deleteHeader("Authorization");
      self.id = null;
      self.title = null;
      self.name = null;
      self.email = null;
      self.access_token = null;
      self.refresh_token = null;
      self.phone_number = null;
      self.is_admin = null;
      self.teacher = null;
      self.student = null;
      self.school_admin = null;
    },
  }));

/**
 * Un-comment the following to omit model attributes from your snapshots (and from async storage).
 * Useful for sensitive data like passwords, or transitive state like whether a modal is open.

 * Note that you'll need to import `omit` from ramda, which is already included in the project!
 *  .postProcessSnapshot(omit(["password", "socialSecurityNumber", "creditCardNumber"]))
 */

type LoginStoreType = Instance<typeof LoginStoreModel>;

export interface LoginStore extends LoginStoreType {}

type LoginStoreSnapshotType = SnapshotOut<typeof LoginStoreModel>;

export interface LoginStoreSnapshot extends LoginStoreSnapshotType {}
