import React from "react";
import PageLayout from "components/PageLayout";
import SchoolAdminTeachers from "./SchoolAdminTeachers";
const TeachersList = () => {
  return <PageLayout
    schoolAdminComponent={<SchoolAdminTeachers />}
  />;
};

export default TeachersList;
