import React, {useState} from "react";
import MDBox from "../MDBox";
import UploadIcon from "@mui/icons-material/Upload";
import MDTypography from "../MDTypography";


const ImageUploader = ({ onImageUpload, picture }) => {
  const [selectedImage, setSelectedImage] = useState(picture || null);

  const handleImageChange = (event) => {
    const file = event.target.files[0];

    if (file) {
      const reader = new FileReader();
      reader.onload = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);

      // Call the callback function with the uploaded image
      onImageUpload(file);
    }
  };

  return (
    <div>
      <input
        type="file"
        accept="image/*"
        id="image-input"
        style={{ display: "none" }}
        onChange={handleImageChange}
      />
      <label htmlFor="image-input">
        <MDBox
          style={{
            border: "1px solid lightgrey",
            borderRadius: 8,
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "8px 0px",
          }}
        >
          {selectedImage && (
            <div style={{ flex: 1 }}>
              <img src={selectedImage} alt="Selected" style={{ maxWidth: "100%" }} />
            </div>
          )}

          <UploadIcon />
          {!selectedImage && (
            <MDTypography color="dark" fontSize={14}>
              Upload Image
            </MDTypography>
          )}
        </MDBox>
      </label>
    </div>
  );
};

export default ImageUploader;
