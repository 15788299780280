import MDBox from "components/MDBox";
import { useParams } from "react-router-dom";
import TeacherCourseNav from "./TeacherCourseNav";
import TeacherCourseProfile from "./TeacherCourseProfile";
import TeacherCourseHome from "./TeacherCourseHome";
import TeacherCourseEnrolment from "./TeacherCourseEnrolment";
import TeacherCourseHistory from "./TeacherCourseHistory";
import TeachersLessons from "../lessons/TeachersLessons";
import {useApi} from "../../services/helpers";
import {useEffect, useState} from "react";
import TeacherCourseWBQBank from "./TeacherCourseWBQBank";

const TeacherCourseDetail = (props, ref) => {
  const { courseId, section} = useParams()
  const api = useApi();
  const [course, setCourse] = useState(props.course)

  const getTeacherCourseById = () => {
    api.getTeacherCourseById(courseId).handle({
      onSuccess: (res) => {
        setCourse(res.data)
      },
      errorMessage: "Error getting course",
    });
  }

  useEffect(() => {
    if (!course)
      getTeacherCourseById()
    // TODO pull from children, as it also have the course object, search @DD$F5
  }, []);

  const courseRoutes = {
    home: <TeacherCourseHome/>,
    profile: <TeacherCourseProfile course={course} getCourse={getTeacherCourseById}/>,
    edit: <TeachersLessons/>,
    enrolment: <TeacherCourseEnrolment/>,
    history: <TeacherCourseHistory/>,
    wbQuestion: <TeacherCourseWBQBank/>,
  }

  return (
    <>
      <MDBox display={"flex"}>
        <TeacherCourseNav course={course}/>
        {courseRoutes[section]}
      </MDBox>
    </>
  );
};

export default TeacherCourseDetail;
