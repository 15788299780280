import React, {useEffect} from "react";
import MDBox from "components/MDBox";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import {Link, useNavigate, useParams} from "react-router-dom";
import { ROUTES } from "../../services/constants";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import MDTypography from "components/MDTypography";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useMaterialUIController } from "context";
import { setHideNavbar } from "context";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import {useStores} from "../../models";
import {useApi} from "../../services/helpers";

const SchoolAdminPageLayout = ({ children }) => {
  const [controller, dispatch] = useMaterialUIController();
  const { loginStore } = useStores();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [course, setCourse] =  React.useState(null);
  const { courseId } = useParams()
  const api = useApi();

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // const getTeacherCourseById = () => {
  //   api.getTeacherCourseById(courseId).handle({
  //     onSuccess: (res) => {
  //       setCourse(res.data)
  //     },
  //     errorMessage: "Error getting course",
  //   });
  //   // TODO pull from parent, as it also have the course object, search @DD$F5
  // }
  //
  // useEffect(() => {
  //   if(!courseId) return;
  //
  //   getTeacherCourseById();
  //
  //
  //
  // }, [courseId]);

  return (
    <MDBox width="100%" height="100%" minHeight="100vh" bgColor="white">
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar sx={({ palette }) => ({ backgroundColor: palette.primary.main, paddingRight: {xs:'8px', md:'16px'}})}>
            <IconButton
              onClick={() => setHideNavbar(dispatch, !controller.hideNavbar)}
              size="large"
              edge="start"
              color="white"
              aria-label="menu"
            >
              <MenuIcon/>
            </IconButton>

            <Box
              display="flex"
              sx={{
                display: "flex",
                flexGrow: 1,
                justifyContent: course ? "space-between" : 'end',
                alignItems: "center",
                // backgroundColor:'red',
              }}
            >
              <MDBox
                display="flex"
                alignItems="center"
                sx={({ palette }) => ({
                  borderRight: `4px solid ${palette.tertiary.main}`,
                  borderLeft: `4px solid ${palette.tertiary.main}`,
                  mx: 2,
                })}
              >
                <IconButton

                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={() => {}}
                  color="inherit"
                >
                  <NotificationsNoneIcon  sx={{width:{xs:'20px', md:'30px'}, height: {xs:'20px', md:'30px'}}} color="white" />
                </IconButton>
                <MDBox>
                  <MDBox display={"flex"} sx={{flexDirection:'column'}}>
                    <MDTypography textAlign={"right"} variant="body2" fontSize={{xs:'13px', md:"14px"}}>
                      <strong>Welcome</strong>
                    </MDTypography>
                    <MDTypography textAlign={"right"} variant="body2" fontSize={{xs:'12px', md:"14px"}} >
                    {loginStore.name}
                    </MDTypography>
                  </MDBox>

                  <MDTypography textAlign={"right"} variant="body2" fontSize={{xs:'10px', md:'14px'}} fontWeight={'medium'}>
                    {`School: ${loginStore?.school_admin?.name}`}
                  </MDTypography>
                </MDBox>
                <MDBox>
                <IconButton
                  size="large"
                  aria-label="account of current user"
                  aria-controls="menu-appbar"
                  aria-haspopup="true"
                  onClick={handleMenu}
                  color="inherit"
                >
                  {loginStore?.school_admin?.logo ?
                    <MDBox
                      component={"img"}
                      src={loginStore?.school_admin?.logo}
                      alt={"Profile Picture"}
                      borderRadius={'50%'}

                      sx={{width:{xs:'20px', md:'30px'}, height: {xs:'20px', md:'30px'}}}
                    />
                    :
                    <AccountCircle color="white" />
                  }
                  <KeyboardArrowDownIcon fontSize="small" color="white" />
                </IconButton>
                <Menu
                  id="menu-appbar"
                  anchorEl={anchorEl}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <MenuItem onClick={() => navigate(ROUTES.PROFILE)}>Profile</MenuItem>
                  {/*<MenuItem onClick={handleClose}>Messages</MenuItem>*/}
                  {/*<MenuItem onClick={handleClose}>Settings</MenuItem>*/}
                </Menu>
                </MDBox>
              </MDBox>
              <MDTypography
                component={Link}
                to={ROUTES.LOGOUT}
                variant="button"
                color="white"
                fontWeight="medium"
              >
                LOG OUT
              </MDTypography>
            </Box>
          </Toolbar>
        </AppBar>
      </Box>
      {children}
    </MDBox>
  );
};

export default SchoolAdminPageLayout;
