import React from "react";
import PageLayout from "components/PageLayout";

import StudentHome from "./StudentHome";
import TeachersHome from "./TeacherHome";
import SchoolAdminHome from "./SchoolAdminHome";
const CoursesList = () => {
  return <PageLayout
    studentComponent={<StudentHome />}
    teacherComponent={<TeachersHome />}
    schoolAdminComponent={<SchoolAdminHome />}
  />;
};

export default CoursesList;
