import React from "react";
import PageLayout from "components/PageLayout";
import SchoolAdminStudents from "./SchoolAdminStudents";
const StudentsList = () => {
  return <PageLayout
    schoolAdminComponent={<SchoolAdminStudents />}
  />;
};

export default StudentsList;
