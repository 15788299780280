import MDBox from "../MDBox";
import MDTypography from "../MDTypography";
import MDButton from "../MDButton";
import {Modal} from "@mui/material";

function ConfirmModal ({handleClose, open, handleConfirm, title, subtitle}) {

  return(
    <Modal open={open} onClose={handleClose}>
      <MDBox sx={{
        position: 'absolute',
        top: '50%',
        left: '50%',
        backgroundColor: '#fff',
        borderRadius: '10px',
        transform: 'translate(-50%, -50%)',
        width: 400,
        boxShadow: 24,
      }}>
        <MDTypography pt={2} sx={{textAlign: 'center', fontSize: "22px", color: '#6A994E'}}>{title}</MDTypography>
        <MDTypography pt={2} sx={{textAlign: 'center', fontSize: "16px", color: '#000'}}>{subtitle}</MDTypography>
        <MDBox pb={2} display={'flex'} justifyContent={'center'} alignItems={'center'} gap={2} pt={2}>
          <MDButton color={'primary'} onClick={() => {
            handleConfirm()
          }}>Confirm</MDButton>
          <MDButton variant={'outlined'} color={'secondary'} onClick={()=>{
            handleClose()
          }}>Cancel</MDButton>
        </MDBox>
      </MDBox>
    </Modal>
  )
}

export default ConfirmModal
