import * as React from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Box, Grid, IconButton, CircularProgress} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import clock from "../../assets/icons/clock.svg";
import book from "../../assets/icons/book-card.svg";
import chat from "../../assets/icons/chat.svg";
import {createSearchParams, useNavigate} from "react-router-dom";
import {ROUTES} from "services/constants";
import {useApi} from "../../services/helpers";
import {CREATE_COURSE_STEPS, MODALITY_OPTIONS} from "../../utils/helpers";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import logo from "../../assets/images/logos/logo.png";
import {useStores} from "../../models";
import {useEffect, useState} from "react";
import moment from "moment";

const CourseCard = ({course}) => {
  const navigate = useNavigate();
  const api = useApi();
  const {loginStore} = useStores();
  const [updateDate, setUpdateDate] = useState(null);
  const [courseStartDate] = useState(course?.nearest_meeting_start_time ? moment(course?.nearest_meeting_start_time) : null)
  const [courseEndDate] = useState(course?.nearest_meeting_end_time ? moment(course?.nearest_meeting_end_time) : null)
  const [glowProps, setGlowProps] = useState({})
  const [currentDiffString, setCurrentDiffString] = useState(null)
  const [currentDiffColor, setCurrentDiffColor] = useState('black')
  const [joinClassLoading, setJoinClassLoading] = useState(false)

  const RED_THRESHOLD = 5 * 60;
  const YELLOW_THRESHOLD = 20 * 60;
  const NOGLOW_THRESHOLD = 60 * 60;

  const diff2color = (diff) => {
    const diffSeconds = diff.asSeconds()
    if (diffSeconds < RED_THRESHOLD) {
      return ["#8A4747", "#AA4747", "#8A4747"];
    } else if (diffSeconds < YELLOW_THRESHOLD) {
      return ["#ffc04a", "#da9b1e", "#ffc04a"];
    } else if (diffSeconds < NOGLOW_THRESHOLD) {
      return ["#8fce6a", "#77bd4d", "#8fce6a"];
    } else {
      return null;
    }
  }

  const colors2glowprops = (colors) => {
    const ida = `anim_${course.id}`
    if (!colors)
      return {}

    return {
      [`@keyframes ${ida}`]: {
        "0%": {
          boxShadow: `0 0 10px ${colors[0]}`,
        },
        "50%": {
          boxShadow: `0 0 20px ${colors[1]}`,
        },
        "100%": {
          boxShadow: `0 0 10px ${colors[2]}`,
        },
      },
      animatedElement: {
        animation: `$$${ida} 1300ms infinite`,
      },
      animation: `${ida} 1300ms infinite`,
    }
  }

  const diff2StringAndColor = (diff) => {
    const diffSeconds = diff.asSeconds()
    const diffMinutes = diff.asMinutes()

    if (diffSeconds > -60 && diffMinutes <= 0) {
      return ["It's starting now!", "#8A4747"]
    }

    if (diffSeconds < 0 && diffSeconds > -120) {
      return [`Started ${parseInt(-diffSeconds)} seconds ago`, "#8A4747"]
    }

    if (diffMinutes < 0) {
      return [`Started ${parseInt(-diffMinutes)} minutes ago`, "#8A4747"]
    }

    if (diffSeconds < 120) {
      return [`Starts in ${parseInt(diffSeconds)} seconds`, "#8A4747"]
    }

    if (diffMinutes < 60) {
      const minuteWord = parseInt(diffMinutes) !== 1 ? "minutes" : "minute"
      return [`Starts in ${parseInt(diffMinutes)} ${minuteWord}`, "#da9b1e"]
    }

    if (diffMinutes < 24 * 60) {
      const minuteRest = parseInt(diffMinutes) % 60
      const hourWord = parseInt(diffMinutes / 60) !== 1 ? "hours" : "hour"
      const minuteWord = parseInt(minuteRest) !== 1 ? "mins" : "min"
      const minuteString = minuteRest > 0 ? ` and ${minuteRest} ${minuteWord}` : ""
      return [`Starts in ${parseInt(diffMinutes / 60)} ${hourWord}${minuteString}`, "#579d3d"]
    }

    return ["Starts " + moment(courseStartDate).format("MM/DD - hh:mm A"), "black"]

  }

  const nearestMeetingAttendance = (courseId) => {
    setJoinClassLoading(true)
    api.getCourseNearestMeetingAttendance(courseId).handle({
      onSuccess: (response) => {
        const data = response.data
        const {is_present, mid} = data
        if (is_present) {
          navigate(ROUTES.GAME_VIEW(mid))
        } else {
          navigate(ROUTES.QR_READER);
        }
      },
      errorMessage: "Error joining class",
      onFinally: () => setJoinClassLoading(false)
    });
  }

  const onJoinClassHandler = (event) => {
    event.stopPropagation();
    nearestMeetingAttendance(course.id)
  }

  useEffect(() => {
    if (!courseStartDate) return

    if (courseEndDate == null || courseEndDate.diff(moment()) < 0) { // past end date
      //setCurrentDifference(null)
      setGlowProps({})
      setCurrentDiffString("")
      setCurrentDiffColor(null)
      return
    }

    const current = moment.duration(courseStartDate.diff(moment()))
    setGlowProps(colors2glowprops(diff2color(current)))
    const [text, color] = diff2StringAndColor(current)
    setCurrentDiffString(text)
    setCurrentDiffColor(color)
  }, [courseStartDate, updateDate])

  useEffect(() => {
    const intervalId = setInterval(() => {
      setUpdateDate(moment());
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);


  return (
    <Card
      sx={{height: "275px", cursor: "pointer", "&:hover": {boxShadow: 4}}}
      onClick={() => {
        if (!loginStore?.student) {
          navigate(`${ROUTES.COURSES}/${course.id}/home`)
        }
      }
      }
    >
      <Box
        sx={{
          height: "194px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          image={course?.image || logo}
          alt="Course"
          sx={{
            margin: 0,
            p: course?.image ? "none" : 2,
            maxHeight: "194px",
            objectFit: course?.image ? "cover" : "contain",
            opacity: course?.image ? "none" : "50%",
          }}
        />
      </Box>
      <CardContent sx={{p: 0,paddingBottom: '0!important', mt:1}}>
        <Grid container>
          <Grid item xs={6} pl={2}>
            <Typography
              sx={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
              variant="body2"
              color={(theme) => theme.palette.primary.main}
              fontWeight="bold"
            >
              {course?.name}
            </Typography>
          </Grid>
          <Grid item xs={6} pr={2}>
            <Typography
              sx={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
              textAlign="right"
              variant="body2"
              fontWeight="bold"
              color={(theme) => theme.palette.primary.main}
            >
              {MODALITY_OPTIONS._LABELS[course?.modality]}
            </Typography>
          </Grid>
          <Grid item xs={12} pl={2}>
            <Typography variant="body2" color={(theme) => theme.palette.primary.main}>
              {/*Period 2 - 2023*/}
            </Typography>
          </Grid>
          <Grid item xs={12} pr={2}>
            <Typography textAlign="right" fontWeight="bold" color={currentDiffColor} fontSize="15px">
              {currentDiffString}
            </Typography>
          </Grid>
          {loginStore?.student && (
            <Grid item xs={12}>
              <Box display="flex" px={1} flexDirection="row" alignItems={"center"}>
                <Box flex={1}>
                  <IconButton>
                    <img src={book} alt="resources"/>
                  </IconButton>
                  <IconButton>
                    <img src={chat} alt="chat"/>
                  </IconButton>
                </Box>
                <IconButton>
                  <img src={clock} alt="time"/>
                </IconButton>
                <MDBox
                  sx={{
                    boxShadow: 2,
                    px: 1,
                    borderRadius: 2,
                    height: "38px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                  onClick={!joinClassLoading ? onJoinClassHandler : null}
                >
                  {!!joinClassLoading ?
                    <CircularProgress color="primary" size={12}/> :
                    <MDTypography
                      textAlign="right"
                      fontWeight="bold"
                      color={"primary"}
                      fontSize="15px"
                    >
                      Join Class
                    </MDTypography>}
                </MDBox>
              </Box>
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  );
};
export const AddCourseCard = () => {
  const navigate = useNavigate();
  return (
    <Card
      onClick={() =>
        navigate({
          pathname: `${ROUTES.NEW_COURSE}`,
          search: `?${createSearchParams({
            step: CREATE_COURSE_STEPS.STEP_1,
          })}`,
        })
      }
      sx={{
        height: "275px",
        cursor: "pointer",
        "&:hover": {boxShadow: 4},
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          height: "100%",
          textAlign: "center",
          justifyContent: "center",
          alignItems: "center",
          p: 0,
          paddingBottom: '0!important'
        }}
      >
        <AddRoundedIcon
          fontSize="100"
          sx={{
            fontSize: "100px",
            color: "rgba(28, 39, 76, 0.25)",
          }}
        />
      </CardContent>
    </Card>
  );
};
export default CourseCard;
