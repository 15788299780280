import * as React from "react";
import PageLayout from "../../components/PageLayout";
import {Navigate} from "react-router-dom";
import {ROUTES} from "../../services/constants";
import NewProduct from "./NewProduct";

const ManageProduct = () => {
  return (
    <PageLayout
      teacherComponent={<NewProduct />}
      schoolAdminComponent={<NewProduct />}
    />
  );
}


export default ManageProduct;
