import { Grid, IconButton } from "@mui/material";
import { BaseModal } from "components/BaseModal";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {useState, useCallback, useEffect, useRef} from "react";
import CloseIcon from "@mui/icons-material/Close";
import {useApi} from "../../services/helpers";
import {QRCodeCanvas} from "qrcode.react";

const CourseInvitationModal = ({ open, setOpen, courseId }) => {
  const api = useApi();
  const [qrCode, setQRCode] = useState(null);

  const getCourseQR = useCallback(async (emails) => {
    api.getCourseInvitationCode(courseId, emails).handle({
      onSuccess: (res) => {
        setQRCode(`${window.location.origin}/?ic=${res.data}`)
      },
      errorMessage: "Error getting course invitation link",
    });
  }, [api, courseId]);

  useEffect(() => {
    if (open) {
      getCourseQR();
    }
  },[open, getCourseQR, api]);

  return (
    <BaseModal open={open} width="40%" minWidth='410px'>
      <MDBox sx={{ display: "flex", m: 2, alignItems: "center", justifyContent:'center' }}>
        <MDTypography variant="body1" color="primary" sx={{ fontSize: 36, flex: 1 }}>
          Course Invitation QR / Link
        </MDTypography>
        <IconButton size="big" onClick={() => setOpen(false)}>
          <CloseIcon />
        </IconButton>
      </MDBox>
        <MDBox sx={{ m: 2, justifyContent: 'center', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
          <MDTypography color="primary" sx={{ mb: 1, }}>Course invitation QR code:</MDTypography>
          {qrCode && <QRCodeCanvas size={256} value={qrCode} />}
          <MDTypography color="primary" sx={{ mt: 3, }}>Course invitation link:</MDTypography>
          {qrCode && <a href={qrCode}>{qrCode}</a>}
        </MDBox>

    </BaseModal>
  );
};

// const Step1 = ({ emailsText, setEmailsText }) => {
//   return (
//     <>
//       <MDTypography variant="body2" style={{ fontWeight: 400 }} color="dark">
//         Add user(s) by email
//       </MDTypography>
//       <MDTypography color="dark" style={{ fontWeight: 300 }} variant="body2">
//         Example: user@school.edu, username@school.edu
//       </MDTypography>
//       <TextField
//         id="outlined-multiline-static"
//         multiline
//         rows={4}
//         fullWidth
//         value={emailsText}
//         onChange={(e) => setEmailsText(e.target.value)}
//         defaultValue="Default Value"
//         sx={{ border: "1px solid lightgrey", borderRadius: 1 }}
//       />
//     </>
//   );
// };
// const Step2 = ({ emails }) => {
//   return (
//     <>
//       <MDBox
//         display="flex"
//         alignItems="center"
//         borderRadius={4}
//         border={(theme) => `1px solid ${theme.palette.primary.main}`}
//         sx={{ padding: 0 }}
//       >
//         <MDBox
//           backgroundColor="red"
//           sx={(theme) => ({
//             backgroundColor: theme.palette.primary.main,
//             display: "flex",
//             // borderRadius: 1,
//           })}
//         >
//           <CheckIcon color="white" sx={{ m: 1 }} />
//         </MDBox>
//
//         <MDTypography color="dark" sx={{ fontWeight: 300, pl: 1 }} variant="body2">
//           The following users are ready to be added to the course
//         </MDTypography>
//       </MDBox>
//       <MDBox sx={{height:'400px', overflow:'hidden'}}>
//       <Grid container mt={2}>
//         <Grid container item sx={{overflow:'auto', maxHeight:'400px'}}>
//         <Grid item xs={4} borderBottom={"1px solid lightgray"}>
//           <MDTypography color="dark" variant="body2">
//             Name
//           </MDTypography>
//         </Grid>
//         <Grid item xs={4} borderBottom={"1px solid lightgray"}>
//           <MDTypography color="dark" variant="body2">
//             Email Address
//           </MDTypography>
//         </Grid>
//         <Grid item xs={4} borderBottom={"1px solid lightgray"}>
//           <MDTypography color="dark" variant="body2">
//             Institution
//           </MDTypography>
//         </Grid>
//         {emails?.map((email) => (
//           <>
//             <Grid item xs={4} py={1} borderBottom={"1px solid lightgray"}>
//               <MDTypography color="dark" variant="body2" sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}></MDTypography>
//             </Grid>
//             <Grid item xs={4} py={1} borderBottom={"1px solid lightgray"}>
//               <MDTypography color="dark" variant="body2" sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}>
//                 {email}
//               </MDTypography>
//             </Grid>
//             <Grid item xs={4} py={1} borderBottom={"1px solid lightgray"}>
//               <MDTypography color="dark" variant="body2" sx={{ whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}></MDTypography>
//             </Grid>
//           </>
//         ))}
//         </Grid>
//       </Grid>
//       </MDBox>
//     </>
//   );
// };

export default CourseInvitationModal;
