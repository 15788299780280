import { TextField, CircularProgress } from '@mui/material';
import { debounce } from 'lodash';
import { Search } from '@mui/icons-material';
import MDBox from '@mui/material/Box';
import {forwardRef, useImperativeHandle, useState} from "react";

const SearchBar = forwardRef(({ loading, setSearchQuery }, ref) => {
  const delayedSearch = debounce(setSearchQuery, 300);
  const [search, setSearch] = useState('');

  const searchFunc = (text = '') => {
    delayedSearch(text);
    setSearch(text);
  };

  const clearInput = () => {
    searchFunc('');
  }

  useImperativeHandle(ref, () => ({
    clearInput,
  }));

  return (
    <MDBox
      mr={3}
      sx={{
        width: '400px',
        height: '44px',
        borderRadius: '20px',
        backgroundColor: '#fff',
        display: 'flex',
        alignItems: 'center',
        px: 2, // Horizontal padding inside the box
        border: '2px solid #6A994E', // Border with color and width
      }}
    >
      <TextField
        fullWidth
        placeholder="Search"
        type="text"
        onChange={(evt) => searchFunc(evt?.target?.value)}
        value={search}
        size="small"
        InputProps={{
          endAdornment: loading === false ? <Search /> : <CircularProgress size={14} color="primary" />,
          disableUnderline: true,
          sx: {
            color: '#6A994E',
          },
        }}
        variant="standard" // Set variant to standard to remove additional borders
        sx={{
          '& .MuiInputBase-input': {
            color: '#6A994E', // Text color
          },
        }}
      />
    </MDBox>
  );
});

export default SearchBar;
