import { Grid } from "@mui/material";
import FormikInput from "components/FormikInput";
import MDButton from "components/MDButton";
import ModalItem from "components/ModalItem";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { useApi } from "../../services/helpers";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "services/constants";
//
const getTodayDate = () => {
  const now = new Date();
  const todayDate = now.toISOString().split('T')[0];
  return todayDate
}
const getCurrentTime = () => {
  const now = new Date();
  const timeOptions = { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
  const currentTime = new Intl.DateTimeFormat('en-US', timeOptions).format(now);
  return currentTime
}
const durationOptions = [
  {id: 1, name: "15 minutes", minutes: 15 },
  {id: 2, name: "30 minutes", minutes: 30 },
  {id: 3, name: "45 minutes", minutes: 45 },
  {id: 4, name: "1 hour", minutes: 60 },
  {id: 5, name: "2 hours", minutes: 120 },
  {id: 6, name: "3 hours", minutes: 180 },
]

const calculateEndTime = (startTime, duration) => {
  const start = new Date(`1970-01-01T${startTime}Z`);
  const end = new Date(start.getTime() + duration * 60000); // duration in milliseconds
  return end.toISOString().substr(11, 8); // Extracting only the time part
};


const InstantMeetingModal = ({ showModal, courseSelected, lessonSelected, handleClose, onCreated }) => {
  const api = useApi();
  const navigate = useNavigate();
  const formikRef = useRef();
  const meetingData = {
    "date": getTodayDate(),
    "course": {id: courseSelected},
    "start_time": getCurrentTime(),
    "end_time": "00:00:00",
    "lesson": lessonSelected,
    // "period": null,
    "repeat_config": null
  }
  const [loading, setLoading] = useState(false);

  const validationSchema = Yup.object().shape({
    title: Yup.string().required('This field is required'),
    aproximate_duration: Yup.object().nullable().required('This field is required')
  });

  const createCourseMeeting = (data) => {
    setLoading(true);
    const validatedData = {
      ...meetingData,
      ...data,
      end_time: calculateEndTime(getCurrentTime(), data.aproximate_duration.minutes)
    };
    //
    api.createCourseMeeting(validatedData).handle({
      onSuccess: (response) => {
        const newMeetingId = response.data.id
        navigate(`${ROUTES.COURSES}/${courseSelected}/home?instantMeetingId=${newMeetingId}`)
      },
      errorMessage: "Error creating course meeting",
      successMessage: "Course meeting created successfully!",
      onFinally: () => setLoading(false),
    });
  };

  return (
    <ModalItem
      scrollable={false}
      open={showModal}
      closeOnClickOutside={false}
      height="auto"
      title="Create Meeting"
      handleClose={() => handleClose()}
    >
      <Formik
        innerRef={formikRef}
        initialValues={{ title: lessonSelected?.description || '', aproximate_duration: durationOptions[0] }}
        validationSchema={validationSchema}
        enableReinitialize={true}
        validateOnBlur={false}
        onSubmit={(values) => createCourseMeeting(values)}
      >
        {({ errors, handleSubmit, setFieldValue }) => (
          <Form style={{ width: "100%" }}>
            <Grid container>
              <Grid item xs={12}>
                <FormikInput name="title" placeholder="Topic" errors={errors} fullWidth />
              </Grid>

              <Grid item xs={12}>
                 <FormikInput
                    type="select"
                    name="aproximate_duration"
                    placeholder="Aproximate Duration"
                    options={durationOptions}
                    initialValue={durationOptions[0]}
                    onChangeSelect={(event) => {
                      if (event.target.value === undefined) {
                        setFieldValue("aproximate_duration", null)
                      }
                    }}
                    errors={errors}
                  />
              </Grid>
              <Grid item xs={12} display="flex" justifyContent="center">
                <MDButton
                  color="primary"
                  loading={loading}
                  disabled={loading}
                  onClick={handleSubmit}
                >
                  Create
                </MDButton>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </ModalItem>
  );
};

export default InstantMeetingModal;
