import React, {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import CourseCard from "./CourseCard";
import {Card, Grid} from "@mui/material";
import logo from "../../assets/images/logos/logo.png"
import {useApi} from "../../services/helpers";
import sandClock2 from "../../assets/icons/sandClock2.svg";
import {ENROLMENT_STATUS, MODALITY_OPTIONS} from "../../utils/helpers";
import {EmptyResponse} from "../../components/EmptyResponse";


const SchoolAdminCourses = () => {
  const api = useApi();
  const [courses, setCourses] = useState([]);
  const [enrolmentCourses, setEnrolmentCourses] = useState([]);

  const getSchoolCourses = () => {
    api.getSchoolCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: "Error getting courses",
    });
  }


  useEffect(() => {
    getSchoolCourses()
  }, []);

  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography flex={1} fontSize={{xs: '24px', md: "30px"}} variant="h3" color="dark" fontWeight="regular">
          Courses
        </MDTypography>
        <MDButton variant="contained" iconOnly onClick={() => {
        }}>
          <TuneRoundedIcon fontSize="50px" sx={{fontSize: "50px"}}/>
        </MDButton>
      </MDBox>
      <Grid container mt={3} direction={'row'} gap={2}>
        {courses?.length > 0 ?
          courses.map((item) => (
            <Grid key={item} display={'contents'} direction={'row'} item xs={12} sm={5} md={4}>
              <CourseCard course={item}/>
            </Grid>
          ))
          :
          <EmptyResponse text={"No courses found"} height={"55vh"}/>
        }
      </Grid>
    </>
  );
};

export default SchoolAdminCourses;
