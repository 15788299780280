import { IconButton } from "@mui/material";
import { Box } from "@mui/system";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { default as React, useEffect, useState } from "react";
import MDButton from "../../components/MDButton";
import { useApi } from "../../services/helpers";
import { EmptyResponse } from "../../components/EmptyResponse";
import QuestionModal from "../presentation/QuestionModal";
import editIcon from "../../assets/icons/edit.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import ConfirmModal from "../../components/ConfirmModal/ConfirmModal";

const TeacherWBQuestion = () => {
  const api = useApi();
  const [questions, setQuestions] = useState(null);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [showQuestionModal, setShowQuestionModal] = useState(false);
  const [showDeleteQuestionModal, setDeleteShowQuestionModal] = useState(false);

  const getWBQuestions = () => {
    api.getWBQuestions().handle({
      onSuccess: (res) => {
        setQuestions(res.data);
      },
      errorMessage: "Error getting lessons",
    });
  };

  const createWBQuestion = (data) => {
    api.createWBQuestion(data).handle({
      onSuccess: (res) => {
        setShowQuestionModal(false);
      },
      errorMessage: "Error creating question",
      successMessage: "Question created successfully!",
      onFinally: () => {
        getWBQuestions();
      },
    });
  };

  const updateWBQuestion = (data) => {
    api.updateWBQuestion(selectedQuestion.id, data).handle({
      onSuccess: (res) => {
        setSelectedQuestion(null);
        setShowQuestionModal(false);
      },
      errorMessage: "Error updating question",
      successMessage: "Question updated successfully!",
      onFinally: () => {
        getWBQuestions();
      },
    });
  };

  const deleteWBQuestion = () => {
    api.deleteWBQuestion(selectedQuestion?.id).handle({
      onSuccess: (res) => {
        setSelectedQuestion(null);
      },
      errorMessage: "Error deleting question",
      successMessage: "Question deleted successfully!",
      onFinally: () => {
        getWBQuestions();
      },
    });
  };

  const handleEdit = (question) => {
    setSelectedQuestion(question);
    setShowQuestionModal(true);
  };

  const handleDelete = (question) => {
    setSelectedQuestion(question);
    setDeleteShowQuestionModal(true);
  };

  const handleCloseModal = () => {
    setSelectedQuestion(null);
    setShowQuestionModal(false);
    setDeleteShowQuestionModal(false);
  };

  useEffect(() => {
    getWBQuestions();
  }, [selectedQuestion]);

  const QuestionItem = ({ question, handleEdit, handleDelete }) => {
    return (
      <>
        <Box
          borderRadius={2}
          boxShadow={2}
          p={2}
          mb={1}
          display="flex"
          alignItems="center"
          justifyContent={"space-between"}
          borderBottom="1px solid rgba(0, 0, 0, 0.25)"
          width={"100%"}
        >
          <MDTypography
            display={"content"}
            sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
            fontSize={{ xs: "16px", md: "24px" }}
            variant="h1"
            color="primary"
            fontWeight="regular"
            title={question?.prompt}
          >
            {question?.prompt}
          </MDTypography>
          <MDBox display={"flex"} alignItems={"center"}>
            <IconButton onClick={() => handleEdit(question)} color="primary">
              <MDBox component={"img"} src={editIcon} alt="edit" />
            </IconButton>
            <DeleteIcon onClick={() => handleDelete(question)} sx={{ cursor: "pointer", ml: 2 }} />
          </MDBox>
        </Box>
      </>
    );
  };

  return (
    <MDBox width={"100%"}>
      <MDBox
        display="flex"
        justifyContent={"space-around"}
        py={1}
        mb={3}
        gap={1}
        width={"100%"}
        borderBottom="1px solid rgba(0, 0, 0, 0.25)"
      >
        <MDTypography
          flex={1}
          fontSize={{ xs: "24px", md: "30px" }}
          variant="h3"
          color="primary"
          fontWeight="regular"
        >
          Student Feedback Questions
        </MDTypography>
        <MDButton
          variant="contained"
          onClick={() => {
            setShowQuestionModal(true);
          }}
          size="small"
          sx={{ boxShadow: 2, "&:hover": { boxShadow: 3 }, mb: 1 }}
        >
          <MDTypography
            sx={{ textWrap: "nowrap" }}
            variant="caption"
            color="secondary"
            fontWeight="bold"
          >
            Add Question +
          </MDTypography>
        </MDButton>
      </MDBox>
      {/* ============ QUESTIONS ============ */}
      <MDBox display={"flex"} flexDirection={"column"} gap={2}>
        {questions?.length > 0 ? (
          questions.map((question) => (
            <QuestionItem
              key={question.id}
              question={question}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          ))
        ) : (
          <EmptyResponse text={"No lessons found"} height={"5vh"} />
        )}
      </MDBox>
      <QuestionModal
        handleClose={handleCloseModal}
        showModal={showQuestionModal}
        onCreated={createWBQuestion}
        onUpdate={updateWBQuestion}
        question={selectedQuestion}
        isWB
      />
      <ConfirmModal
        handleClose={handleCloseModal}
        open={showDeleteQuestionModal}
        handleConfirm={deleteWBQuestion}
        title={"Are you sure?"}
        subtitle={"Are you sure you want to delete the question?"}
      />
    </MDBox>
  );
};

export default TeacherWBQuestion;
