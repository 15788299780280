import React from "react";
import PageLayout from "components/PageLayout";
import TeacherCourses from "./TeacherCourses";
import StudentCourses from "./StudentCourses";
import SchoolAdminCourses from "./SchoolAdminCourses";
const CoursesList = () => {
  return <PageLayout
    teacherComponent={<TeacherCourses />}
    studentComponent={<StudentCourses />}
    schoolAdminComponent={<SchoolAdminCourses />}
  />;
};

export default CoursesList;
