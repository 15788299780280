import React, {useEffect, useState} from "react";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import {Grid} from "@mui/material";
import {useApi} from "../../services/helpers";
import {changeState} from "../../utils/helpers";
import SearchBar from "../../components/SearchBar";
import Pagination from "../../components/Pagination/Pagination";
import StudentTeacherCard from "../students/StudentTeacherCard";

const INITIAL_STATE = {
  counts: 0,
  itemsPerPage: 0,
  currentPage: 1,
  teachers: [],
  search: '',
  loading: false,
  page_size: 12
}

const SchoolAdminTeachers = () => {
  const api = useApi();
  const [pagSt, setPagSt] = useState(INITIAL_STATE);

  const searchProducts = (search) => {
    changeState(setPagSt,  'search', search)
    getSchoolTeachers({search: search})
  }

  const getSchoolTeachers = (searchParams) => {
    changeState(setPagSt, 'loading', true)
    api.getSchoolTeachers(searchParams).handle({
      onSuccess: (result) => {
        const {count, results} = result.data
        setPagSt({
          ...pagSt,
          teachers: results,
          counts: count,
          itemsPerPage: results.length,
          currentPage: searchParams.page || pagSt.currentPage,
        });
      },
      errorMessage: "Error getting teachers",
      onFinally: () => changeState(setPagSt, 'loading', false)
    });
  }


  useEffect(() => {
    getSchoolTeachers({search: ''})
  }, []);

  const renderItems = (item) => {
    return (
      <Grid key={item.id} item xs={12} sm={6} lg={3} mt={4}>
        <StudentTeacherCard
          name={item.name}
          image={item.image}
        />
      </Grid>
    )
  }

  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)" gap={3}>
        <MDTypography flex={1} variant="h3" color="dark" fontWeight="regular">
          Teachers
        </MDTypography>
        <SearchBar
          loading={pagSt.loading}
          setSearchQuery={searchProducts}
        />
      </MDBox>
      <MDBox px={3}>
        <Grid container mt={3} spacing={{xs: 0, md: 4}}>
          {pagSt.teachers.map((item) => renderItems(item))}
        </Grid>
        {pagSt.teachers.length === 0 &&<MDBox display={'flex'} justifyContent={'center'} alignItems={'center'} height={'500px'}>
          <MDTypography variant={'title'} color={'primary'}>No items found</MDTypography>
        </MDBox>}
      </MDBox>
      <Pagination
        currentPage={pagSt.currentPage}
        totalCount={pagSt.counts}
        pageSize={12}
        onPageChange={page => {
          getSchoolTeachers({...{search: pagSt.search}, page: page})
        }}
      />
    </>
  );
};

export default SchoolAdminTeachers;
