import {Button, Grid, Typography} from "@mui/material"
import React from "react"
import {useApi} from "../../services/helpers"
import {useParams} from "react-router-dom"
import MDButton from "../../components/MDButton"
import {CLASS_MODES} from "../../services/constants"


const ModeSwitcher = ({classMode, CLASS_MODES, handleClickQuestionMode, handleClickTaskMode, handleClickDiscussionMode, styles}) => {
    return (
        <div
            style={{
                backgroundColor: "#E7E7E7",
                height: 48,
                borderRadius: 10,
            }}
        >
            <Button
                style={classMode === CLASS_MODES.QUESTIONS ? styles.btnStyle : styles.btnWrapperStyle}
                onClick={handleClickQuestionMode}
            >
                <span>Questions</span>
            </Button>
            <Button
                style={classMode === CLASS_MODES.TASKS ? styles.btnStyle : styles.btnWrapperStyle}
                onClick={handleClickTaskMode}
            >
                <span>Tasks</span>
            </Button>
          <Button
            style={classMode === CLASS_MODES.DISCUSSION ? styles.btnStyle : styles.btnWrapperStyle}
            onClick={handleClickDiscussionMode}
          >
            <span>Discussion</span>
          </Button>
        </div>
    );
};

const PenaltySwitcher = ({isOn, setNavigatePenaltyOn, styles}) => {
    return (
        <div
            style={{
                backgroundColor: "#E7E7E7",
                height: 48,
                borderRadius: 10,
            }}
        >
            <Button
                style={!isOn ? styles.btnStyle : styles.btnWrapperStyle}
                onClick={() => setNavigatePenaltyOn(false)}
            >
                <span>OFF</span>
            </Button>
            <Button
                style={isOn ? styles.btnStyle : styles.btnWrapperStyle}
                onClick={() => setNavigatePenaltyOn(true)}
            >
                <span>ON</span>
            </Button>
        </div>
    );
};

export const ManagementTab = ({
                                  start,
                                  end,
                                  classMode,
                                  navigatePenaltyOn,
                                  setNavigatePenaltyOn,
                                  pauseAutoEngagement,
                                  continueAutoEngagement,
                              }) => {
    const {courseId, meetingId} = useParams()

    const api = useApi()

    const changeClassMode = (newClassMode) => {
        // setClassMode(newClassMode)
        api.meetingAction(meetingId, `class_mode_${newClassMode}`).handle({
            successMessage: "Class mode changed",
            errorMessage: "Error changing class mode",
        })
    }

    const startMeeting = () => {
        api.meetingAction(meetingId, "start").handle({
            successMessage: "Meeting started...",
            errorMessage: "Error starting the meeting",
        })
    }

    const endMeeting = () => {
        // here should be sending the exit-ticket question calls
        api.meetingAction(meetingId, "end").handle({
            successMessage: "Meeting ended...",
            errorMessage: "Error ending the meeting",
        })

    }

    const handleClickTaskMode = () => {
        pauseAutoEngagement()
        changeClassMode(CLASS_MODES.TASKS)
    }

    const handleClickQuestionMode = () => {
        continueAutoEngagement()
        changeClassMode(CLASS_MODES.QUESTIONS)
    }

  const handleClickDiscussionMode = () => {
    continueAutoEngagement()
    changeClassMode(CLASS_MODES.DISCUSSION)
  }

    const statusStr =
        start && end
            ? `Ended ${end.fromNow()}`
            : start && !end
                ? `Started ${start.fromNow()}`
                : "Not Started"

    return (
        <Grid container direction="column" justifyContent="flex-start" alignItems="center" mt={3}>
            <Grid item xs={9}>
                <Typography color="primary" fontWeight="bold" mb={2}>
                    Class Mode
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <ModeSwitcher
                    classMode={classMode}
                    CLASS_MODES={CLASS_MODES}
                    handleClickQuestionMode={handleClickQuestionMode}
                    handleClickTaskMode={handleClickTaskMode}
                    handleClickDiscussionMode={handleClickDiscussionMode}
                    styles={styles}
                />
            </Grid>
            {/*  */}
            <Grid item xs={9} mt={2}>
                <Typography color="primary" fontWeight="bold" mb={2}>
                    Navigate Away Penalty
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <PenaltySwitcher
                    isOn={navigatePenaltyOn || navigatePenaltyOn === null}
                    setNavigatePenaltyOn={setNavigatePenaltyOn}
                    styles={styles}
                />
            </Grid>
            {/*  */}
            <Grid item xs={9} mt={4}>
                <Typography color="primary" fontWeight="bold" ml={2}>
                    Class status: {statusStr}
                </Typography>
            </Grid>
            <Grid item xs={3} py={2}>
                <MDButton
                    variant="contained"
                    onClick={() => startMeeting()}
                    color={!start && !end ? "primary" : "secondary"}
                    disabled={!!start || !!end}
                >
                    Start class
                </MDButton>
            </Grid>
            <Grid item xs={3} py={2}>
                <MDButton
                    variant="contained"
                    onClick={() => endMeeting()}
                    color={!!start && !end ? "primary" : "secondary"}
                    disabled={!start || !!end}
                >
                    End class
                </MDButton>
            </Grid>
        </Grid>
    )
}

const styles = {
    btnStyle: {
        background: "#6A994E",
        fontWeight: "700",

        fontSize: 14,
        marginTop: 3,
        color: "white",
    },
    btnWrapperStyle: {
        border: "none",
        backgroundColor: "rgb(231, 231, 231)",
        color: "black",
        fontSize: 14,
        fontWeight: "500",
        marginTop: 3,
        opacity: 0.6,
    },
}
