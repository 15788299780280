import { Close, AttachMoney, MoneyOff } from '@mui/icons-material'
import { Avatar, Grid, IconButton, Typography } from '@mui/material'
import { Box } from '@mui/system'
import MDBox from 'components/MDBox'
import MDTypography from 'components/MDTypography'
import React, { useEffect, useState } from 'react'
import { EmptyResponse } from '../../components/EmptyResponse'
import { useApi } from '../../services/helpers'
import MeetingCard from '../lessons/MeetingCard'
import { StudentUnderstanding, StudentsEngagement } from './charts'

const BORDER = '1px solid rgba(0, 0, 0, 0.25)'

const SchoolAdminHome = () => {
  const api = useApi()
  const [courses, setCourses] = useState([])
  const [meetings, setMeetings] = useState([])
  const [products, setProducts] = useState([])

  const getTeacherCourses = () => {
    api.getTeacherCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data)
      },
      errorMessage: 'Error getting courses',
    })
  }

  const getNearestActivities = () => {
    api.getNearestActivities().handle({
      onSuccess: (res) => {
        setMeetings(res.data)
      },
      errorMessage: 'Error getting meetings',
    })
  }

  const getMyProducts = () => {
    api.getMyProducts().handle({
      onSuccess: (res) => {
        setProducts(res.data)
        console.log('res.data', res)
      },
      errorMessage: 'Error getting products',
    })
  }


  // useEffect(() => {
  //   getTeacherCourses()
  //   getNearestActivities()
  //   getMyProducts()
  // }, [])

  const renderProduct = (product) => {
    return (
      <Box
        display="flex"
        borderRadius={2}
        bgcolor="rgba(106, 153, 78, 0.1)"
        mb={2}
        border="1px solid lightgray"
        sx={{ maxWidth: '465px' }}
        key={product.id}
      >
        <Box display="flex" alignItems="center" px={1} bgcolor="#fff" borderRadius={2}>
          <Avatar src={product.image} />
        </Box>
        <Box p={1} flex={1}>
          <Typography fontSize="18px" color="primary">
            {product.name} - Price: {product.price} - Quantity {product.quantity}
          </Typography>
          <Typography fontSize="14px">Student: {product.student}</Typography>
        </Box>
      </Box>
    )
  }

  const [selectedClass, setSelectedClass] = useState(undefined)
  return (
    <>
      <MDBox display="flex" py={1} m={0}>
        <MDTypography
          flex={1}
          py={1}
          borderBottom="1px solid rgba(0, 0, 0, 0.25)"
          variant="h3"
          color="dark"
          fontWeight="regular"
        >
          Home
        </MDTypography>
      </MDBox>
      <Grid container xs={12} justifyContent={'flex-start'}>
        <Grid item xs={5} md={3} lg={3} py={2}>
          {/*<MDTypography borderBottom={BORDER} color="primary">*/}
          {/*  Participation in classes*/}
          {/*</MDTypography>*/}
          {/*<MDBox>*/}
          {/*  {courses.map((c) => (*/}
          {/*    <MDBox*/}
          {/*      key={`id-${c?.id}`}*/}
          {/*      onClick={() => setSelectedClass(c.id)}*/}
          {/*      sx={(theme) => ({*/}
          {/*        fontSize: { xs: '18px', md: '24px' },*/}
          {/*        whiteSpace: 'nowrap',*/}
          {/*        overflow: 'hidden',*/}
          {/*        textOverflow: 'ellipsis',*/}
          {/*        p: 1,*/}
          {/*        borderLeft: `6px solid ${*/}
          {/*          c.id === selectedClass ? theme.palette.primary.main : 'white'*/}
          {/*        }`,*/}
          {/*        background: `rgba(106, 153, 78, ${c.id === selectedClass ? 0.1 : 0})`,*/}
          {/*        color:*/}
          {/*          c.id === selectedClass ? theme.palette.primary.main : theme.palette.dark.main,*/}
          {/*        '&:hover': {*/}
          {/*          cursor: 'pointer',*/}
          {/*          borderLeft: `6px solid ${theme.palette.primary.main}`,*/}
          {/*        },*/}
          {/*      })}*/}
          {/*    >*/}
          {/*      {c.name}*/}
          {/*    </MDBox>*/}
          {/*  ))}*/}
          {/*</MDBox>*/}
        </Grid>

        <Grid item xs={7} md={4} lg={5}>
          {/*{selectedClass && <StudentsEngagement />}*/}
          {/*{selectedClass && <StudentUnderstanding />}*/}
        </Grid>

        <Grid item xs={12} md={5} lg={4} p={2}>
          {/*<MDTypography borderBottom={BORDER} color="primary">*/}
          {/*  Next Activities*/}
          {/*</MDTypography>*/}
          {/*<MDBox py={2}>*/}
          {/*  {meetings?.length > 0 ? (*/}
          {/*    meetings.map((meeting) => <MeetingCard meeting={meeting} />)*/}
          {/*  ) : (*/}
          {/*    <EmptyResponse text={'No meetings found'} height={'5vh'} />*/}
          {/*  )}*/}
          {/*</MDBox>*/}
          {/*<MDTypography borderBottom={BORDER} color="primary">*/}
          {/*  Recent Comments*/}
          {/*</MDTypography>*/}
          {/*<MDBox py={2}>*/}
          {/*  <CommentSection />*/}
          {/*</MDBox>*/}
          {/*<MDTypography borderBottom={BORDER} color="primary">*/}
          {/*  Marketplace Transactions*/}
          {/*</MDTypography>*/}
          {/*<MDBox py={2}>*/}
          {/*  {products.map((product) => renderProduct(product))}*/}
          {/*</MDBox>*/}
        </Grid>
      </Grid>
    </>
  )
}
const CommentSection = () => {
  const [comments] = useState([
    { id: 1, subject: 'Math', title: 'Help needed', message: 'Question not written correctly' },
    { id: 2, subject: 'Science', title: 'Help ', message: 'I do not undestand this' },
  ])
  return comments.map((c) => <ComentCard key={c.id} {...c} />)
}
const ComentCard = ({ subject, message, title, user }) => {
  return (
    <Box
      display="flex"
      borderRadius={2}
      bgcolor="rgba(106, 153, 78, 0.1)"
      mb={2}
      border="1px solid lightgray"
      sx={{ maxWidth: '465px' }}
    >
      <Box display="flex" alignItems="center" px={1} bgcolor="#fff" borderRadius={2}>
        <Avatar />
      </Box>
      <Box p={1} flex={1}>
        <Typography fontSize="18px" color="primary">
          {subject} - {title}
        </Typography>
        <Typography fontSize="14px">{message}</Typography>
      </Box>
      <IconButton size="small">
        <Close />
      </IconButton>
    </Box>
  )
}
export default SchoolAdminHome
