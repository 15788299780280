import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import CourseCard, { AddCourseCard } from "./CourseCard";
import { Grid } from "@mui/material";
import { useApi } from "../../services/helpers";
import {RealtimeContext} from "../../services/streaming/provider";

const TeacherCourses = () => {
  const api = useApi();
  const [courses, setCourses] = useState([]);
  const { disableWebsocket } = React.useContext(RealtimeContext);

  const getTeacherCourses = () => {
    api.getTeacherCourses().handle({
      onSuccess: (res) => {
        setCourses(res.data);
      },
      errorMessage: "Error getting courses",
    });
  };

  useEffect(() => {
    getTeacherCourses();
  }, []);

  useEffect(() => {
    disableWebsocket();
  }, [disableWebsocket]);

  return (
    <>
      <MDBox display="flex" py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <MDTypography
          flex={1}
          fontSize={{ xs: "24px", md: "30px" }}
          variant="h3"
          color="dark"
          fontWeight="regular"
        >
          Courses
        </MDTypography>

        <MDButton variant="contained" iconOnly onClick={() => {}}>
          <TuneRoundedIcon fontSize="50px" sx={{ fontSize: "50px" }} />
        </MDButton>
      </MDBox>
      <Grid container mt={3}  gap={2}>
        <Grid item xs={12} sm={6} lg={3} mt={4}>
          <AddCourseCard />
        </Grid>
        {courses?.map((item) => (
          <Grid key={item.id} item xs={12} sm={6} lg={3} mt={4}>
            <CourseCard course={item} />
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default TeacherCourses;
