import RefreshIcon from "@mui/icons-material/Refresh";
import DoneIcon from "@mui/icons-material/Done";
import EditIcon from "@mui/icons-material/Edit";
import PhotoIcon from "@mui/icons-material/Photo";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
import PaidIcon from "@mui/icons-material/Paid";
import {Avatar, Box, Button, Card, CardContent, Chip, Grid, IconButton, Stack, Typography} from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { MathJax } from "better-react-mathjax";
import Tooltip from "@mui/material/Tooltip";
import ModalItem from "components/ModalItem";
import { Form, Formik } from "formik";
import React, {useEffect, useRef, useState} from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import paperPlane from "../../assets/icons/PaperPlaneTilt.svg";
import FormikInput from "../../components/FormikInput";
import MDTypography from "../../components/MDTypography";
import { useApi } from "../../services/helpers";
import { STUDENT_TASK_STATUSES } from "../../services/constants";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import { makeStyles } from "@mui/styles";
import Checkbox from "@mui/material/Checkbox";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DownloadIcon from '@mui/icons-material/Download';

const TASK_SWITCH = {
  TASKS_GROUPS: "Tasks Groups",
  HISTORY: "History",
};

const TASK_STATUS_COLORS = {
  approved: "success",
  rejected: "error",
  pending: "yellow",
}

const FILTER_STUDENT_TASK = {
  BY_GROUP: "group",
  BY_TASK: "task",
};


const TaskSwitcher = ({ option, onChangeOption, styles }) => {
  return (
    <div
      style={{
        backgroundColor: "#E7E7E7",
        height: 48,
        borderRadius: 10,
      }}
    >
      <Button
        style={option === TASK_SWITCH.TASKS_GROUPS ? styles.btnStyle : styles.btnWrapperStyle}
        onClick={() => onChangeOption(TASK_SWITCH.TASKS_GROUPS)}
      >
        <span>{TASK_SWITCH.TASKS_GROUPS}</span>
      </Button>
      <Button
        style={option === TASK_SWITCH.HISTORY ? styles.btnStyle : styles.btnWrapperStyle}
        onClick={() => onChangeOption(TASK_SWITCH.HISTORY)}
      >
        <span>{TASK_SWITCH.HISTORY}</span>
      </Button>
    </div>
  );
};

const TaskCompletionCard = ({
  record,
  loading = false,
  onApprove = () => {},
  onReject = () => {},
  onEdit = () => {},
  onViewImage,
  onSelect,
}) => {
  const useStyles = makeStyles((theme) => ({
    popper: {
      backgroundColor: "transparent",
    },
    arrow: {
      "&:before": {
        border: "1px solid #E6E8ED",
      },
      color: theme.palette.common.white,
    },
    tooltip: {
      backgroundColor: theme.palette.common.white,
      border: "1px solid #E6E8ED",
      color: "#4A4A4A",
      fontSize: 11,
    },
  }));
  let classes = useStyles();
  //
  return (
    <Card>
      <MDBox display={"flex"} alignItems={"center"} >
        <Checkbox
          onChange={(e, value) => {
            onSelect(record);
          }}
        />
        <MDBox>
          <Box sx={{ display: "flex", alignItems: "center", p: 2 }}>
            <Avatar
              alt={record?.student?.name}
              src={record?.student?.image}
              sx={{ maxWidth: '4vw', aspectRatio: '1', mr: 2 }}
            />
            <Box  sx={{ width: '100%', maxWidth: '100%' }}>
              <Typography variant="h6" color="primary" sx={{width:{sm:'250px', md:'250px', lg:'145px', xl:'250px'}, textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                {record?.student?.name}
              </Typography>
            </Box>
          </Box>

          <CardContent sx={{ flex: 1, width: "100%" }}>
            <Typography variant="h6" color="primary">
              <Tooltip
                title={record?.task?.description}
                placement={"top"}
                classes={{ arrow: classes.arrow, tooltip: classes.tooltip, popper: classes.popper }}
              >
                {record?.task?.name}
              </Tooltip>
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {record?.task?.description}
            </Typography>
            {/* BUTTONS */}
            <MDBox
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 1,
                mt: 1,
                width: "100%",
              }}
            >
              {/* APPROVE */}
              <MDButton
                  sx={{padding: '10px', minHeight:'20px', minWidth:'20px'}}
                disabled={loading}
                color="primary"
                onClick={onApprove}
              >
                <DoneIcon fontSize="large" />
              </MDButton>
              {/* REJECT */}
              <MDButton
                  sx={{padding: '10px', minHeight:'20px', minWidth:'20px'}}
                disabled={loading}
                color="error"
                onClick={onReject}

              >
                <RefreshIcon color={"white"} fontSize="large" />
              </MDButton>
              {/* EDIT REWARD */}
              <Button
                  sx={{padding: '10px', minHeight:'20px', minWidth:'20px'}}
                disabled={loading}
                color="secondary"
                variant="outlined"
                size="small"
                onClick={() => onEdit()}
              >
                <EditIcon color={"primary"} fontSize="large" />
              </Button>
              {!!onViewImage && (
                <Button
                    sx={{padding: '10px', minHeight:'20px', minWidth:'20px'}}
                  disabled={loading}
                  color="secondary"
                  variant="outlined"
                  size="small"
                  onClick={() => onViewImage()}
                >
                  <PhotoIcon color={"primary"} fontSize="large" />
                </Button>
              )}
            </MDBox>
          </CardContent>
        </MDBox>
      </MDBox>
    </Card>
  );
};

const EditTaskRewardModal = ({
  showModal = false,
  handleClose,
  task = {},
  loading = false,
  onSubmit = () => {},
}) => {
  const { id: eventId, student = {}, task: taskItem = {} } = task;
  const initialValues = {
    rewardAmount: task.task?.reward || 0,
    justification: "",
  };

  const validationSchema = Yup.object({
    rewardAmount: Yup.number()
      .min(0, "Reward amount cannot be negative")
      .required("Reward amount is required"),
    justification: Yup.string().required("Justification is required"),
  });

  const onFormikSubmit = (values) => {
    const { rewardAmount, justification } = values;
    // sendTaskApproval
    onSubmit(
      eventId,
      student.id,
      taskItem.id,
      STUDENT_TASK_STATUSES.APPROVED,
      rewardAmount,
      justification
    );
  };
  //
  return (
    <ModalItem
      scrollable={true}
      open={showModal}
      closeOnClickOutside={false}
      title={`Edit reward`}
      handleClose={handleClose}
      widthContainer={"100%"}
    >
      <Box sx={{ width: "100%" }}>
        <Box>
          <Stack direction="row" alignItems="center" spacing={2}>
            {task.student?.image ? (
              <Avatar src={task.student.image} alt={task.student.name} />
            ) : (
              <Avatar>{task.student?.name?.charAt(0)}</Avatar>
            )}
            <Typography>{task.student?.name}</Typography>
          </Stack>
        </Box>
        {/*  */}
        <Box sx={{ marginTop: 2, marginBottom: 4 }}>
          <Box display={"flex"} gap={2} alignItems={"center"}>
            <Typography>Task: </Typography>
            <Typography fontSize={"medium"}>{task.task?.name}</Typography>
          </Box>
          <Typography fontSize={"small"}>{task.task?.description}</Typography>
        </Box>
        {/*  */}
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onFormikSubmit}
        >
          {({ isSubmitting }) => (
            <Form>
              <Box sx={{ mt: 2 }}>
                <FormikInput name="rewardAmount" type={"number"} label="Reward Amount" />
              </Box>
              <Box sx={{ mt: 2 }}>
                <FormikInput
                  name="justification"
                  type={"textarea"}
                  label="Justification"
                  rows={2}
                />
              </Box>
              <Box
                sx={{
                  mt: 2,
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  // border: '2px solid red',
                }}
              >
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading}
                  sx={{ width: "100%" }}
                >
                  Submit
                </Button>
                {/* <Button type="button" variant="outlined" color="secondary" onClick={handleClose}>
                  Cancel
                </Button> */}
              </Box>
            </Form>
          )}
        </Formik>
      </Box>
    </ModalItem>
  );
};

const TaskGroupDetailModal = ({ showModal = false, handleClose, taskGroup = {} }) => {
  const { tasks = [] } = taskGroup;
  //
  return (
    <ModalItem
      scrollable={true}
      open={showModal}
      closeOnClickOutside={false}
      // height="70%"
      title={`${taskGroup?.name}`}
      handleClose={handleClose}
      // width={'100%'}
      // widthContainer={'100%'}
    >
      <List
        sx={{
          width: "100%",
          maxWidth: 360,
          bgcolor: "background.paper",
          display: "flex",
          flexDirection: "column",
          gap: 3,
        }}
      >
        {!!tasks &&
          tasks.length > 0 &&
          tasks.map((task, tidx) => {
            const { name, description, reward } = task;
            return (
              <ListItem key={`task-${task.id}`}>
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    // justifyContent: 'space-around',
                  }}
                >
                  <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                    <MDTypography color="primary">{name}</MDTypography>
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <MDTypography color={"secondary"}>{reward}</MDTypography>
                      <PaidIcon color="primary" />
                    </Box>
                  </Box>
                  <Box fontSize="small">{description}</Box>
                </Box>
              </ListItem>
            );
          })}
      </List>
    </ModalItem>
  );
};

const TaskGroupCard = ({taskGroup, index, sendTaskGroup, onClickFilter, openTaskDetailModalHandler}) => {
  const [collapsed, setCollapsed] = useState(false)
  return (
    <Grid item px={2} my={'4px'}>
      <Card sx={{ p: 3, justifyContent: "space-between"}}>
        <MDBox display={"flex"} justifyContent={"space-between"}>
          <MDTypography noWrap
            fontSize={{ xs: "16px", md: "20px" }}
            fontWeight={"bold"}
            sx={{cursor: "pointer"}}
            color={"primary"} onClick={() =>
            onClickFilter({field: FILTER_STUDENT_TASK.BY_GROUP, id: taskGroup?.id})}
          >
            <MathJax>{`${index + 1}. ${taskGroup.name}`}</MathJax>
          </MDTypography>
          <IconButton onClick={() => setCollapsed(!collapsed)}>
            {collapsed ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </MDBox>
        {collapsed &&
          <MDBox display={"flex"} flexDirection={"column"}>
            {taskGroup?.tasks.map(task => (
              <MDTypography
                color={"primary"}
                sx={{textDecoration: 'underline', cursor:"pointer", pl:1}}
                onClick={() => onClickFilter({field: FILTER_STUDENT_TASK.BY_TASK, id:task?.id})}
              >
                {task?.name}
              </MDTypography>
            ))}
          </MDBox>
        }
        <Box
          display="flex"
          flexDirection="row"
          mt={1}
          pt={1}
          gap={1}
          borderTop="1px solid lightgray"
          justifyContent={"space-between"}
        >
          <Box display="flex" alignItems="center">
            <Button
                sx={{padding:'8px', minWidth:'50px'}}
              color="secondary"
              variant="outlined"
              size="medium"
              onClick={() => openTaskDetailModalHandler(taskGroup)}
            >
              <FormatListBulletedIcon color={"primary"} fontSize="large" />
            </Button>
          </Box>
          <Box>
            <Button
                sx={{padding:'8px', minWidth:'50px'}}
              color="primary"
              variant="contained"
              size="medium"
              onClick={() => sendTaskGroup(taskGroup)}
            >
              <img src={paperPlane} alt={"sent"} />
            </Button>
          </Box>
        </Box>
      </Card>
    </Grid>
  )
}

const StudentGroupHistory = ({students, width}) => {
  const [openTaskModal, setOpenTaskModal] = React.useState(false);
  const [selectedTask, setSelectedTask] = React.useState(null);
  const [selectedStudent, setSelectedStudent] = React.useState(null);
  const typographyRef = useRef(null);
  const [containerSize, setContainerSize] = useState(0)
  const minWidth = 180;

  return (
    <>
      <MDBox width={"100%"} overflow-x={"hidde"} paddingY={2} sx={{maxHeight: '700px'}} >
        {students.map((student, indexStudent) => (
          <MDBox  display={"flex"} flexDirection={'row'} alignItems={"end"} sx={{paddingY:1, borderBottom: '2px solid rgba(106, 153, 78, 0.25)',  width: width }}>
            {/*STUDENT*/}
            <MDBox sx={{width:'250px'}}>
            <MDTypography fontSize={{ xs: "16px", md: "20px" }} color={"primary"} fontWeight={"bold"} sx={{width:'250px',}}>
              <MathJax>{student.name}</MathJax>
            </MDTypography>
            </MDBox>
            {/*TASKS GROUPS*/}
            {student.groups.map((group, index) => (
              <MDBox borderLeft="2px solid rgba(106, 153, 78, 0.25)" sx={{display:'flex', flexDirection:'column', justifyContent:'space-between', paddingLeft:1 }}>
                {indexStudent === 0 &&
                    <MDBox sx={{ mb:2, minWidth:'150px'}}>
                  <MDTypography  ref={typographyRef} noWrap fontSize={{ xs: "16px", md: "20px" }} color={"primary"} fontWeight={"bold"}   sx={{  width: containerSize.typographyWidth > 0 ? `${containerSize.typographyWidth}px` : '200px'}}>
                    <MathJax style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{`${index + 1}. ${group.name}`}</MathJax>
                  </MDTypography>
                      <MDBox display="flex" flexDirection="row" alignItems={"center"} >
                      {group.tasks?.map(task => (
                  <Box display={'flex'} flexWrap={'nowrap'} flexDirection={'row'} alignItems={"center"} width={'200px'} >
                    <MDTypography fontSize={{ xs: "16px", md: "20px" }} color={"primary"} sx={{lineHeight:'22px', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>
                  <MathJax style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}>{task.name}</MathJax>
                </MDTypography>
              </Box>))}
                      </MDBox>
                    </MDBox>
                }
                {/*CIRCLE*/}
                <Box
                    minWidth={`${minWidth}px`}
                    width={containerSize.boxWidth > 0 ? `${containerSize.boxWidth}px` : 'auto'}
                  display="flex"
                  flexWrap="nowrap"
                  mt={1}
                  pt={1}
                >
                  {group?.tasks.map((task) =>(
                    <MDBox width={'200px'} display={"flex"} flexDirection={"column"}>
                      <MDBox
                        key={task?.id}
                        height={"30px"}
                        width={"30px"}
                        borderRadius={"50%"}
                        border={"1px solid #E6E8ED"}
                        bgColor={TASK_STATUS_COLORS[task?.status]}
                        onClick={() => {
                          setOpenTaskModal(true)
                          setSelectedTask(task)
                          setSelectedStudent(student)
                        }}
                        sx={{cursor: "pointer"}}
                      />
                    </MDBox>
                  ))}
                </Box>
              </MDBox>
            ))}
          </MDBox>
        ))}
      </MDBox>
      <ModalItem
        scrollable={false}
        open={openTaskModal}
        closeOnClickOutside={false}
        height="auto"
        handleClose={() => {
          setOpenTaskModal(false);
          setSelectedTask(null);
        }}
        paddingStyle={1}
        contentStyle={{ justifyContent: "center" }}
        contentContainterStyle={{ width: "auto" }}
      >
        <MDBox width={"100%"}>
          <Stack direction="row" alignItems="center" spacing={2}>
            {selectedStudent?.image ? (
              <Avatar src={selectedStudent?.image} alt={selectedStudent?.name} />
            ) : (
              <Avatar>{selectedStudent?.name?.charAt(0)}</Avatar>
            )}
            <Typography>{selectedStudent?.name}</Typography>
          </Stack>
          <MDTypography fontSize={{ xs: "16px", md: "20px" }} color={"primary"} fontWeight={"bold"}>
            <MathJax>{selectedTask?.name}</MathJax>
          </MDTypography>
          <MDTypography fontSize={{ xs: "8px", md: "16px" }} color={"secondary"} >
            <MathJax>{selectedTask?.description}</MathJax>
          </MDTypography>
          {!!selectedTask?.picture?
            <a href={selectedTask?.picture} target={"_blank"}>
              <MDBox
                component={"img"}
                src={selectedTask?.picture}
                maxWidth={"60vw"}
                maxHeight={"50vh"}
              />
            </a>
          :
            <MDBox display={"flex"} justifyContent={"center"}>
              <MDTypography fontSize={{ xs: "16px", md: "20px" }} color={"error"}>
                No picture submitted
              </MDTypography>
            </MDBox>
          }
        </MDBox>
      </ModalItem>
    </>
  )
}

export const TaskGroupsTab = ({
                                taskGroups = [],
                                studentTasks = [],
                                taskHistory = [],
                                reloadTaskHistory = ()=>{},
}) => {
  const { courseId, meetingId } = useParams();
  const api = useApi();
  // task group detail modal
  const [selectedTask, setSelectedTask] = React.useState({});
  const [selectedTaskList, setSelectedTaskList] = React.useState([]);
  const [showTaskDetailModal, setShowTaskDetailModal] = React.useState(false);
  // override reward modal
  const [selectedStudentTask, setSelectedStudentTask] = React.useState({});
  const [showEditTaskRewardModal, setShowEditTaskRewardModal] = React.useState(false);
  const [showTaskImageModal, setShowTaskImageModal] = React.useState(false);
  const [currentTaskImage, setCurrentTaskImage] = React.useState(null);
  const [taskOption, setTaskOption] = React.useState(TASK_SWITCH.TASKS_GROUPS);
  const [sendTaskApprovalLoading, setSendTaskApprovalLoading] = React.useState(false);
  const [filteredListTaskGroupStudent, setFilteredTaskGroupStudent] = React.useState([]);
  const [containerSize, setContainerSize] = useState(0)
  const containerRef = useRef(null);
  const [isOverflowing, setIsOverflowing] = useState(false);
  useEffect(() => {
    let resizeTimeout;
    const handleResize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        if (containerRef.current) {
          const isOverflowingX = containerRef.current.scrollWidth > containerRef.current.offsetWidth;
          setIsOverflowing(isOverflowingX);
        }
      }, 150);
    };

    handleResize();

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
      clearTimeout(resizeTimeout);
    };
  }, [taskOption]);
 console.log(isOverflowing);
  const openTaskDetailModalHandler = (taskGroup) => {
    setSelectedTask(taskGroup);
    setShowTaskDetailModal(true);
  };
  const closeTaskDetailModalHandler = () => {
    setSelectedTask({});
    setShowTaskDetailModal(false);
  };
  //
  const openEditTaskRewardModalHandler = (studentTask) => {
    setSelectedStudentTask(studentTask);
    setShowEditTaskRewardModal(true);
  };
  const closeEditTaskRewardModalHandler = () => {
    setSelectedStudentTask({});
    setShowEditTaskRewardModal(false);
  };

  const sendTaskGroup = (taskGroup) => {
    api.sendTaskGroup(meetingId, taskGroup.id).handle({
      successMessage: "Tasks sent!",
      errorMessage: "Error sending tasks",
    });
  };


  const handleSelectStudentTask = (record) => {
    if (selectedTaskList.includes(record)) {
      setSelectedTaskList(selectedTaskList.filter((studentTask) => studentTask?.id !== record?.id));
    } else {
      setSelectedTaskList([...selectedTaskList, record]);
    }
  };

  const filterStudentTaskGroup = (filter) => {
    filter.field === FILTER_STUDENT_TASK.BY_GROUP?
      setFilteredTaskGroupStudent(studentTasks.filter( item => item?.group === filter.id)) :
      setFilteredTaskGroupStudent(studentTasks.filter( item => item?.task?.id === filter.id))
  };

  const sendTaskApproval = (
    eventId,
    studentId,
    taskId,
    status = STUDENT_TASK_STATUSES.APPROVED,
    reward = 0,
    justification
  ) => {
    const data = {
      meeting_event: eventId,
      student: studentId,
      task: taskId,
      status: status,
      reward: reward,
      custom_reward_justification: justification,
    };
    //
    setSendTaskApprovalLoading(true);
    api.sendTaskApproval(meetingId, data).handle({
      successMessage: `Student task ${status} successfully!`,
      errorMessage: "Error updating student task",
      onFinally: () => {
        setSendTaskApprovalLoading(false);
        closeEditTaskRewardModalHandler();
        setSelectedTaskList(selectedTaskList.filter((studentTask) => studentTask?.id !== eventId));
      },
    });
  };

  const sendListTaskApproval = (status, all = "") => {
    const listToUse = all === "all" ? filteredListTaskGroupStudent : selectedTaskList;

    const formatData = listToUse.map((taskEvent) => ({
      ...taskEvent,
      meeting_event: taskEvent?.id,
      student: taskEvent?.student?.id,
      task: taskEvent?.task?.id,
      status,
      reward: taskEvent?.task?.reward,
    }));

    //
    setSendTaskApprovalLoading(true);
    api.sendListTaskApproval(meetingId, { task_student_list: formatData }).handle({
      successMessage: `Student tasks ${status} successfully!`,
      errorMessage: "Error updating student tasks",
      onFinally: () => {
        setSendTaskApprovalLoading(false);
        setSelectedTaskList([]);
      },
    });
  };

  useEffect(() => {
    reloadTaskHistory()
    // console.log("history", taskHistory)
  },[])

  useEffect(() => {
    setFilteredTaskGroupStudent(studentTasks)
  },[studentTasks])


  return (
    <Grid container mt={3}>
      {taskOption === TASK_SWITCH.TASKS_GROUPS &&
        <Grid
          item
          container
          xs={12}

          md={4}
          lg={3}
          direction="column"
          wrap={"nowrap"}
          paddingRight={1}
        >
          <Grid
            item
            xs={12}
            gap={1}
            display={"flex"}
            flexBasis={"0 !important"}
            alignItems={"center"}
            justifyContent={"space-around"}
            flexWrap={'wrap'}
          >
            <Typography color="primary" fontWeight="bold">
              Submitted Tasks
            </Typography>
            <MDButton
                sx={{minWidth:'20px', padding:'10px', flexWrap:'nowrap'}}
              onClick={() => setFilteredTaskGroupStudent(studentTasks)}
            >
              Show all
            </MDButton>
          </Grid>

          <Grid
            item
            container
            direction={"column"}
            xs={12}
            height={'73vh'}
            maxHeight={'73vh'}
            sx={{overflow:'auto', flexWrap:'nowrap'}}
            // py={1}
          >
            {taskGroups?.map((tg, index) => (
              <TaskGroupCard
                key={tg.id}
                taskGroup={tg}
                index={index}
                sendTaskGroup={sendTaskGroup}
                onClickFilter={filterStudentTaskGroup}
                openTaskDetailModalHandler={openTaskDetailModalHandler}
              />
            ))}
          </Grid>
        </Grid>
      }
      {/* ============= */}
      <Grid
        container
        item
        xs={12}
        md={taskOption === TASK_SWITCH.TASKS_GROUPS? 8: 0}
        lg={taskOption === TASK_SWITCH.TASKS_GROUPS? 9 :0}
        direction={"column"}
        justifyContent={'space-between'}
        wrap={"nowrap"}
        sx={{ borderLeft:{ xs: "none", md: "1px solid rgba(0, 0, 0, 0.25)"} }}
        maxHeight={'77vh'}
        height={'77vh'}
      >
        <Grid
          item
          xs={12}
          flexBasis={"0 !important"}
        >
          <MDBox display={"flex"} justifyContent={"center"}>
            <TaskSwitcher option={taskOption} onChangeOption={setTaskOption} styles={styles} />
          </MDBox>
        </Grid>

        {taskOption === TASK_SWITCH.TASKS_GROUPS && (
            <Grid overflow={'auto'} xs={12}>
          <Grid
            item
            flexDirection={'row'}
            display={'flex'}
            flexWrap={'wrap'}
            maxHeight={'889px'}
            alignItems={"flex-start"}
          >
            {/* TASKS */}
            {filteredListTaskGroupStudent?.map((record) => (
              <Grid key={record.id} item xs={12} sm={12} lg={6} px={2} py={2} >
                <TaskCompletionCard
                  record={record}
                  loading={sendTaskApprovalLoading}
                  onApprove={() =>
                    sendTaskApproval(
                      record.id,
                      record.student.id,
                      record.task.id,
                      STUDENT_TASK_STATUSES.APPROVED,
                      record.task.reward
                    )
                  }
                  onReject={() =>
                    sendTaskApproval(
                      record.id,
                      record.student.id,
                      record.task.id,
                      STUDENT_TASK_STATUSES.REJECTED,
                      record.task.reward
                    )
                  }
                  onEdit={() => {
                    openEditTaskRewardModalHandler(record);
                  }}
                  onViewImage={
                    record.task_submit_image
                      ? () => {
                        setShowTaskImageModal(true);
                        setCurrentTaskImage(record.task_submit_image);
                      }
                      : null
                  }
                  onSelect={handleSelectStudentTask}
                />
              </Grid>
            ))}
          </Grid>
          </Grid>
        )}

        {/*/!* BUTTONS *!/*/}
        {taskOption === TASK_SWITCH.TASKS_GROUPS && (
            <Grid item flexBasis={"0 !important"} alignContent={"end"} xs={12}>
              <MDBox display={"flex"} flexDirection={"column"} gap={1} m={1}>
                <MDBox display={"flex"} gap={1}>
                  <MDButton
                      variant="contained"
                      onClick={() => {
                        sendListTaskApproval(STUDENT_TASK_STATUSES.APPROVED, "all");
                      }}
                      color={"primary"}
                      sx={{ flex: "1 0px" }}
                      disabled={studentTasks.length === 0}
                  >
                    Approve all
                  </MDButton>
                  <MDButton
                      variant="contained"
                      onClick={() => {
                        sendListTaskApproval(STUDENT_TASK_STATUSES.REJECTED, "all");
                      }}
                      color={"error"}
                      sx={{ flex: "1 0px" }}
                      disabled={studentTasks.length === 0}
                  >
                    Reject all
                  </MDButton>
                </MDBox>
                <MDBox display={"flex"} gap={1}>
                  <MDButton
                      variant="contained"
                      onClick={() => {
                        sendListTaskApproval(STUDENT_TASK_STATUSES.APPROVED);
                      }}
                      color={"primary"}
                      sx={{ flex: "1 0px" }}
                      disabled={studentTasks.length === 0}
                  >
                    Approve selected
                  </MDButton>
                  <MDButton
                      variant="contained"
                      onClick={() => {
                        sendListTaskApproval(STUDENT_TASK_STATUSES.REJECTED);
                      }}
                      color={"error"}
                      sx={{ flex: "1 0px" }}
                      disabled={studentTasks.length === 0}
                  >
                    Reject selected
                  </MDButton>
                </MDBox>
              </MDBox>
            </Grid>
        )}
        {taskOption === TASK_SWITCH.HISTORY && (
          <Grid
            item
            container
            xs={12}
            px={2}
            display={"flex"}
            gap={1}
          >
            {/* TASKS */}
            <Card ref={containerRef} sx={{width: "100%", p:1, display: "flex", flexDirection:"row", overflow:'auto',}}>
              {/*{taskHistory?.map((student, index) => (*/}
                <StudentGroupHistory width={isOverflowing ? 'fit-content' : '100%'} students={taskHistory}/>

              {/*))}*/}
            </Card>
          </Grid>
        )}
      </Grid>
      {/*  */}
      <TaskGroupDetailModal
        showModal={showTaskDetailModal}
        handleClose={closeTaskDetailModalHandler}
        taskGroup={selectedTask}
      />
      <EditTaskRewardModal
        showModal={showEditTaskRewardModal}
        handleClose={closeEditTaskRewardModalHandler}
        task={selectedStudentTask}
        loading={sendTaskApprovalLoading}
        onSubmit={sendTaskApproval}
      />
      <ModalItem
        scrollable={false}
        open={showTaskImageModal}
        closeOnClickOutside={false}
        height="auto"
        title="Task Image"
        handleClose={() => {
          setShowTaskImageModal(false);
          setCurrentTaskImage(null);
        }}
        contentStyle={{ justifyContent: "center", width: "100%" }}
      >
        <MDBox width={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
          {!!currentTaskImage && (
            <a href={currentTaskImage} target={"_blank"}>
              <MDBox
                component={"img"}
                src={currentTaskImage}
                maxWidth={"60vw"}
                maxHeight={"50vh"}
              />
            </a>
          )}
        </MDBox>
      </ModalItem>
    </Grid>
  );
};

const styles = {
  btnStyle: {
    background: "#6A994E",
    fontWeight: "700",

    fontSize: 14,
    marginTop: 3,
    color: "white",
  },
  btnWrapperStyle: {
    border: "none",
    backgroundColor: "rgb(231, 231, 231)",
    color: "black",
    fontSize: 14,
    fontWeight: "500",
    marginTop: 3,
    opacity: 0.6,
  },
};
