import React, {useEffect, useRef, useState} from "react";
import MDTypography from "components/MDTypography";
import {Grid} from "@mui/material";
import MDButton from "components/MDButton";
import MDBox from "components/MDBox";
import {useLocation, useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useApi, useLoginStore} from "../../services/helpers";
import {useApiMessages} from "../../services/api/api-messages";

import * as Yup from "yup";
import {Form, Formik} from "formik";
import FormikInput from "../../components/FormikInput";

import ImageUploader from "../../components/ImageUploader";
import {ROUTES} from "../../services/constants";
import {changeState} from "../../utils/helpers";


const NewProduct = () => {
  const api = useApi();
  const navigate = useNavigate();
  const location = useLocation();
  const { productId} = useParams()
  const { school_admin} = useLoginStore();
  const INITIAL_STATE = {
    loading: false,
    data: location.state ? location.state : null,
    courses: [],
  }
  const messages = useApiMessages('product', 'products')
  const formikRef = useRef();
  const [pagSt, setPagSt] = useState(INITIAL_STATE);


  const validationSchema = Yup.object().shape({
    name: Yup.string().required(),
    description: Yup.string().required(),
    price: Yup.number().required(),
    stock: Yup.number().required(),
  });

  const initialValues = {
    name: "" || pagSt?.data?.name,
    description: "" || pagSt?.data?.description,
    price: "" || pagSt?.data?.price,
    stock: "" || pagSt?.data?.stock,
    image: "" || pagSt?.data?.image,
    course: "" || pagSt?.data?.course
  }

  const getTeacherCourses = () => {
    api.getTeacherCourses().handle({
      onSuccess: (res) => {
        changeState(setPagSt,'courses', res.data)
      },
      errorMessage: "Error getting courses",
    })
  }

  const getSchoolCourses = () => {
    api.getSchoolCourses().handle({
      onSuccess: (res) => {
        changeState(setPagSt, 'courses', res.data)
      },
      errorMessage: "Error getting courses",
    })
  }


  const onCreate = (values) => {
    changeState(setPagSt,'loading', true)
    api.createProduct(values).handle({
      onSuccess: (res) => {
        navigate(ROUTES.PRODUCT_DETAIL(res.response.id), {state: res.response})
      },
      successMessage: messages.create.success,
      errorMessage: messages.create.error,
      onError: (err) => {
        console.log('err ', err);
      },
      onFinally: () => changeState(setPagSt,'loading', false)
    });

  }

  const onUpdate = (data) => {
    changeState(setPagSt,'loading', true)
    const dataToSend = {...data, id: productId, product_id: productId}
    if (typeof dataToSend.image === "string") {
      delete dataToSend.image
    }
    api.updateProduct(dataToSend).handle({
      onSuccess: (res) => {
        navigate(ROUTES.PRODUCT_DETAIL(res.response.id), {state: res.response})
      },
      successMessage: messages.update.success,
      errorMessage: messages.update.error,
      onFinally: () => changeState(setPagSt,'loading', false)
    });
  }

  useEffect(() => {
    school_admin ? getSchoolCourses() : getTeacherCourses()
  }, [])

  return (
      <Grid container py={1} borderBottom="1px solid rgba(0, 0, 0, 0.25)">
        <Grid item xs={12} container direction={'row'} justifyContent={'space-between'} gap={2}>
          <Formik
            innerRef={formikRef}
            initialValues={initialValues}
            validationSchema={validationSchema}
            validateOnBlur={false}
            onSubmit={(values) =>
              {
                const valuesToSend = {...values}
                if (valuesToSend.course) valuesToSend.course = valuesToSend.course.id
                pagSt.data ? onUpdate(valuesToSend) : onCreate(valuesToSend)
              }
            }
          >
            {({errors, setFieldValue, values}) => (
              <Form style={{display: "flex", flexDirection: "column", flex: 1}}>
                <MDTypography flex={1} fontSize={{xs: '24px', md: "30px"}} variant="h3" color="dark" fontWeight="regular">
                  {pagSt.data ? 'Edit' : 'New'} Product
                </MDTypography>
                <Grid container>
                  <Grid item container xs={12} direction={'row'}>
                    <Grid item xs={6}>
                      <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
                        Product Name
                      </MDTypography>
                      <FormikInput
                        name="name"
                        placeholder="Product Name"
                        errors={errors}
                      />
                      <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
                        Product Description
                      </MDTypography>
                      <FormikInput
                        name="description"
                        placeholder="Product Description"
                        errors={errors}
                      />
                      <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
                        Product Price
                      </MDTypography>
                      <FormikInput
                        name="price"
                        placeholder="Product Price"
                        type={'number'}
                        errors={errors}
                      />
                      <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: 1, sm: 3}}>
                        Product Stock
                      </MDTypography>
                      <FormikInput
                        name="stock"
                        placeholder="Product Stock"
                        type={'number'}
                        errors={errors}
                      />
                      <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: -1, sm: 3}}>
                        Product Course
                      </MDTypography>
                      <FormikInput
                        type={"autocomplete"}
                        placeholder={"Product Course"}
                        fieldName={"student"}
                        value={values.course}
                        options={pagSt.courses}
                        accessKey={"name"}
                        onChange={(value) => {
                          setFieldValue('course', value)
                        }}
                      />
                      <MDTypography color="primary" fontSize={{xs: '18px', md: "20px"}} mt={{xs: -1, sm: 3}}>
                        Product Image
                      </MDTypography>
                      <ImageUploader
                        picture={initialValues.image}
                        onImageUpload={(image) => setFieldValue("image", image)}
                      />
                      <MDBox>
                        <MDBox display="flex" justifyContent="flex-end" my={4}>
                          <MDButton
                            color="primary"
                            type="submit"
                            sx={{mr: 2}} loading={pagSt.loading}
                            disabled={pagSt.loading}>
                            Save
                          </MDButton>
                          <MDButton onClick={() => navigate(-1)}>Cancel</MDButton>
                        </MDBox>
                      </MDBox>
                    </Grid>
                  </Grid>
                </Grid>
              </Form>
            )}
          </Formik>
        </Grid>
      </Grid>
  );
};

export default NewProduct;
