import {useEffect, useRef, useState} from "react";
import {useLoginStore} from "../helpers";


export const useWebSocketComms = (ws_url) => {
    const loginStore = useLoginStore()
    const [useBackChannels, setUseBackChannels] = useState(false)
    const wsRef = useRef(null)
    const messageListeners = useRef([])
    const reconnectInterval = useRef(null); // Referencia para almacenar el intervalo de reconexión

    const logWsProvider = false;

    useEffect(() => {
        const connectWebSocket = () => {
            if (wsRef.current == null || wsRef.current.readyState === WebSocket.CLOSED) {
                wsRef.current = new WebSocket(ws_url);
                wsRef.current.onopen = () => {
                    if (logWsProvider) console.log('ws opened');
                    // Limpiar el intervalo de reconexión si se ha abierto la conexión
                    clearInterval(reconnectInterval.current);
                };
                wsRef.current.onclose = () => {
                    if (logWsProvider) console.log('ws closed');
                    // Volver a conectar después de un intervalo de tiempo
                    reconnectInterval.current = setInterval(connectWebSocket, 500);
                };
                wsRef.current.onerror = (e) => {
                    if (logWsProvider) console.log('ws error', e);
                    // Volver a conectar en caso de error
                    wsRef.current.close();
                };
                wsRef.current.onmessage = (e) => {
                    if (logWsProvider) console.log('ws message', e);
                    const message = JSON.parse(e.data);
                    messageListeners.current.forEach(l => l(message));
                };
            }
        };

        if (useBackChannels) {
            if(!!loginStore.access_token && !!loginStore.id) {
                connectWebSocket();
            }
        } else {
            if (wsRef.current) {
                wsRef.current.close();
                wsRef.current = null;
            }
        }

        // Limpiar el intervalo de reconexión al desmontar el componente
        return () => clearInterval(reconnectInterval.current);
    }, [useBackChannels, ws_url, loginStore.access_token, loginStore.id]);




    const send_ws_message_raw = (message) => {
        if (wsRef.current.readyState === WebSocket.OPEN) {
            wsRef.current.send(JSON.stringify(message));
        }
    }

    const enableWebsocket = () => {
        setUseBackChannels(true)
    }

    const disableWebsocket = () => {
        setUseBackChannels(false)
    }

    const publish = (topic, message, retain= false) => {
        send_ws_message_raw({type: 'publish', topic, payload: message, retain: !!retain})
    }

    const subscribe = (topic) => {
        send_ws_message_raw({type: 'subscribe', topic})
    }

    const unsubscribe = (topic) => {
        send_ws_message_raw({type: 'unsubscribe', topic})
    }

    const addMessageListener = (listener) => {
        messageListeners.current.push(listener)
    }

    const removeMessageListener = (listener) => {
        messageListeners.current = messageListeners.current.filter(l => l !== listener)
    }

    const sendMeetingId = (meetingId) => {
        send_ws_message_raw({type: 'meetingId', payload: meetingId})
    }


    return {
        enableWebsocket,
        disableWebsocket,
        publish,
        subscribe,
        unsubscribe,
        addMessageListener,
        removeMessageListener,

        sendMeetingId
    }
}

