import * as React from "react";

import Card from "@mui/material/Card";
import CardMedia from "@mui/material/CardMedia";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import {Box, Grid, IconButton, CircularProgress} from "@mui/material";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import clock from "../../assets/icons/clock.svg";
import book from "../../assets/icons/book-card.svg";
import chat from "../../assets/icons/chat.svg";
import {createSearchParams, useNavigate} from "react-router-dom";
import {ROUTES} from "services/constants";
import {useApi} from "../../services/helpers";
import {CREATE_COURSE_STEPS, MODALITY_OPTIONS} from "../../utils/helpers";
import MDBox from "../../components/MDBox";
import MDTypography from "../../components/MDTypography";
import logo from "../../assets/images/logos/logo.png";
import {useStores} from "../../models";
import {useEffect, useState} from "react";
import moment from "moment";

const StudentTeacherCard = ({name, image}) => {

  return (
    <Card
      sx={{height: "275px", cursor: "pointer", "&:hover": {boxShadow: 4}}}
    >
      <Box
        sx={{
          height: "194px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CardMedia
          component="img"
          image={image || logo}
          alt="studentTeacher"
          sx={{
            margin: 0,
            p:  image ? "none" : 2,
            maxHeight: "194px",
            objectFit: image ? "cover" : "contain",
            opacity:  image ? "none" : "50%",
          }}
        />
      </Box>
      <CardContent sx={{p: 0,paddingBottom: '0!important', mt:1, width: 400, ml:1}}>
            <Typography
              sx={{whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}}
              variant="body2"
              color={(theme) => theme.palette.primary.main}
              fontWeight="bold"
            >
              { name}
            </Typography>
      </CardContent>
    </Card>
  );
};
export default StudentTeacherCard;
