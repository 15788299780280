import Draggable from "react-draggable";
import {Resizable} from "re-resizable";
import {IconButton, Typography} from "@mui/material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import MDBox from "../../components/MDBox";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import QRCode from "react-qr-code";
import scan_me from "../../assets/images/scan_me.png";
import React, {useState} from "react";
import {observer} from "mobx-react";
import {useStores} from "../../models";


const MeetingDisplayQRElement = observer(({currentCode}) => {
    const rootStore = useStores();
    const { qrX, qrY } = rootStore;

    const [qrAsBubble, setQrAsBubble] = useState(false);
    const [qrSize, setQrSize] = useState({width: 320, height: 320});
    const [qrAnimating, setQrAnimating] = useState(false);
    const [dragDisabled, setDragDisabled] = useState(false);
    const [showScanMe, setShowScanMe] = useState(true);
    const [bubbleClickable, setBubbleClickable] = useState(true);

    const qrAnimation = 'width 0.1s linear, height 0.1s linear'
    //const qrAnimation = 'width 0.1s ease-in-out, height 0.1s ease-in-out'

    const onDrag = (evt, { x, y }) => {
        setBubbleClickable(false)
    };


    const onDragStop = (evt, { x, y }) => {
        rootStore?.setQRPosition(x, y);
        setTimeout(() => {
            setBubbleClickable(true)
        },10)
    };

    const onResizeStart = () => {
        setDragDisabled(true);
    }

    const onResizeStop = (e, direction, ref, d) => {
        setDragDisabled(false);
        setQrSize((prev) => ({
            width: prev.width + d.width,
            height: prev.height + d.height,
        }));
    }

    const onResize = (evt, dir, elRef) => {
        const newShow = elRef.getBoundingClientRect().width > 210
        if (showScanMe !== newShow) {
            setShowScanMe(newShow)
        }
    }

    const qrProps = {
        true: {
            style: {
                background: "#6A994E",
                borderRadius: "30px",
                // margin: "20px",
                position: "absolute",
                top: 0,
                left: 0,
                transition: qrAnimation
            },
            size: {width: 62, height: 62},
            className: "handle",
            enable: false,
        },
        false: {
            ...(qrAnimating ? {} : {
                minHeight: 170,
                minWidth: 170,
            }),
            // minHeight:170,
            // minWidth:170,
            style: {
                background: "#f0f0f0",
                borderRadius: "30px",
                // margin: "200px",
                position: "absolute",
                top: 0,
                left: 0,
                // transition:'width,height 1s ease-in-out'
                ...(dragDisabled ? {} : {transition: qrAnimation})
            },
            size: qrSize,
            enable: {
                top: false,
                left: false,
                topRight: false,
                bottomLeft: false,
                topLeft: false,

                right: true,
                bottom: true,
                bottomRight: true,
            }
        }
    }

    return (
        <Draggable
            axis="both"
            bounds="body"
            handle=".handle"
            defaultPosition={{x: 50, y: 50}}
            // position={null}
            grid={[5, 5]}
            scale={1}
            disabled={dragDisabled}
            onDrag={onDrag}
            onStop={onDragStop}
        >

            <Resizable
                lockAspectRatio={true}
                onResizeStart={onResizeStart}
                onResizeStop={onResizeStop}
                onResize={onResize}
                {...qrProps[qrAsBubble]}
            >
                {qrAsBubble ?

                    <IconButton
                        style={{
                            margin: '5px',
                            color: 'white'
                        }}
                        size={"large"}
                        onClick={() => {
                            if (!bubbleClickable) return
                            setQrAsBubble(false)
                            setQrAnimating(true)
                            setTimeout(() => {
                                setQrAnimating(false)
                            }, 200)
                        }}

                    >
                        <QrCodeIcon/>
                    </IconButton> :
                    qrAnimating ? null : (<>
                        <MDBox
                            bgColor={"#6A994E"}
                            sx={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                borderTopLeftRadius: "30px",
                                borderTopRightRadius: "30px",
                                height: "60px",
                                width: "100%",
                            }}
                            className="handle"
                        >
                            <Typography color="white.main" fontWeight="bold" textOverflow={"ellipsis"}
                                        overflow={"hidden"}>
                                ATTENDANCE
                            </Typography>
                            {showScanMe && <IconButton
                                style={{
                                    margin: '5px',
                                    color: 'white',
                                    position: "absolute",
                                    right: 0,
                                    top: 0,
                                }}
                                size={"large"}
                                onClick={() => setQrAsBubble(true)}
                            >
                                <RemoveCircleOutlineIcon/>
                            </IconButton>}
                        </MDBox>
                        <MDBox display={"flex"} alignItems={"center"} justifyContent={"center"} height={"100%"}
                               flexDirection={"column"} style={{
                            padding: "10px",
                        }} className="handle">
                            <QRCode
                                value={currentCode}
                                style={{
                                    height: 1024,
                                    width: 1024,
                                    ...(showScanMe ? {} : {paddingBottom: "60px"})
                                }}
                            />
                            {showScanMe && <img src={scan_me} alt="scan_me" style={{
                                // width: "70%",
                                objectFit: "cover",
                                minHeight: "90px",
                                paddingBottom: "60px",
                                margin: "5px",
                                height: "30%",
                            }}/>}
                        </MDBox></>)}
            </Resizable>
        </Draggable>
    )
})

export default MeetingDisplayQRElement;
