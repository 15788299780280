
import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import moment from "moment";
import React, { useEffect, useState } from "react";
import newtab2 from "../../assets/icons/newtab-1.svg";
import newtab from "../../assets/icons/newtab.svg";
import play from "../../assets/icons/play.svg";
import stop from "../../assets/icons/stop.svg";
import tablet2 from "../../assets/icons/tablet-1.svg";
import tablet from "../../assets/icons/tablet.svg";
import logo from "../../assets/images/logos/logo.png";
import MDBox from "../../components/MDBox";
import MDButton from "../../components/MDButton";
import MDTypography from "../../components/MDTypography";
import { ROUTES } from "../../services/constants";
import { useApi } from "../../services/helpers";
import { RealtimeContext } from "../../services/streaming/provider";

const colors2glowprops = (colors) => {
    const ida = `anim_meeting`
    if (!colors)
        return {}

    return  {
        [`@keyframes ${ida}`]:{
            "0%": {
                boxShadow: `0 0 10px ${colors[0]}`,
            },
            "50%": {
                boxShadow: `0 0 20px ${colors[1]}`,
            },
            "100%": {
                boxShadow: `0 0 10px ${colors[2]}`,
            },
        },
        animatedElement: {
            animation: `$$${ida} 1300ms infinite`,
        },
        animation: `${ida} 1300ms infinite`,
    }
}

const MeetingCard = ({ meeting }) => {
  const api = useApi()
  const {
    startCapture, stopCapture, markServer, isServerStreaming, streamingId,
    enableWebsocket, subscribe, addMessageListener, removeMessageListener
  } = React.useContext(RealtimeContext);
  const [meetingStatus, setMeetingStatus] = useState(null)
  const [streaming, setStreaming] = useState(false)
  const [presentationPressed, setPresentationPressed] = useState(false)
  const [managementPressed, setManagementPressed] = useState(false)

  console.log(streamingId, meeting.id)

  useEffect(() => {
    markServer()
  },[markServer])

  const meetingId = meeting.id
  const asks_topic = `meeting/${meetingId}/asks`
  const end_topic = `meeting/${meetingId}/end`
  const start_topic = `meeting/${meetingId}/start`
  const streaming_topic = `meeting/${meetingId}/streaming`
  const glowProps = colors2glowprops(["#8fce6a", "#77bd4d", "#8fce6a"])
  const meetingIsStreaming = (isServerStreaming && streamingId === meetingId)

  const updateStatus = () => {
    api.getMeetingStatus(meetingId).handle({
      onSuccess: (res) => {
        setMeetingStatus(res.data)
      },
      errorMessage: "Error getting meeting status",
    })
  }

  useEffect(() => {
      if (!isServerStreaming) return // TODO better

    api.meetingAction(
        meeting.id,
        isServerStreaming ? 'start_stream' : 'stop_stream'
    ).handle({
      errorMessage: 'Failed to update meeting status',
    })
  },[isServerStreaming])

  useEffect(() => {
    const listener = (message) => {
      if (message.type === "welcome") {
        subscribe(end_topic);
        subscribe(start_topic);
        subscribe(streaming_topic);
      } else if (message?.type === "message") {
         if (message?.topic === end_topic) {
          updateStatus()
        } else if (message?.topic === start_topic) {
          updateStatus()
        } else if (message?.topic === streaming_topic) {
           const value = message?.payload === "true"
          setStreaming(value)

        }
      }
    }
    addMessageListener(listener)
    enableWebsocket();
    return () => {
      removeMessageListener(listener)
    }
  }, [addMessageListener, asks_topic, enableWebsocket, removeMessageListener, subscribe]);

  useEffect(() => {
    updateStatus()
  },[])

  return (
    <Card sx={{ boxShadow: 4, cursor: "pointer", "&:hover": { boxShadow: 6 }, mt:1, minWidth:'250px', mr:2}} >
      <MDBox display="flex" flexDirection="column" >
        <MDBox display="flex" borderBottom="1px solid rgba(0, 0, 0, 0.25)">
          <MDBox sx={{borderRadius:'12px 0 0 0', borderRight:"10px solid #6A994E", minWidth:'50px', display:'flex', alignItems:'center'}}>
            <CardMedia
              component="img"
              image={meeting?.course?.image || logo}
              alt="Course"
              sx={{
                borderRadius:"12px 0 0 0",
                minWidth:'50px',
                maxWidth:'150px',
                height:'100%',
                margin: 0,
                opacity: meeting?.course?.image? "none":"50%",
                objectFit: "cover",
              }}
            />
          </MDBox>

          <CardContent sx={{width:'100%', p:"0 0 0 5px", maxHeight:'80px'}}>
            <MDBox display="flex" flexDirection="column" sx={{width:'82%', p:0 }} >
              <MDBox display="flex" justifyContent="space-between" >
                <MDTypography
                    fontSize={{xs:'16px', md:"20px"}}
                    variant="h5"
                    color="primary"
                    fontWeight="bold"
                    mt={"5px"}
                    sx={{ textAlign:"right", whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis'}}
                >
                  {meeting?.lesson?.name}
                </MDTypography>
                <MDTypography
                  variant="body2"
                  color="dark"
                  mt={"5px"}
                  sx={{ textAlign:"right", whiteSpace:'nowrap', overflow:'hidden', textOverflow:'ellipsis', minWidth:'50px'}}
                >
                  {meeting?.title || "-"}
                </MDTypography>
              </MDBox>
              {meeting?.period &&
                <MDBox display="flex" justifyContent="space-between">
                  <MDTypography
                    variant="caption"
                    color="dark"
                  >
                    Period
                  </MDTypography>
                  <MDTypography
                    variant="caption"
                    color="dark"
                  >
                    {meeting?.period || "-"}
                  </MDTypography>
                </MDBox>
              }
              <MDBox display={"flex"} flexDirection={'column'} alignItems={'end'}>
                <MDTypography
                  variant="caption"
                  color="dark"
                  fontSize='11px'
                  sx={{ textTransform: 'uppercase'}}
                >
                  {moment(`${meeting?.date} 00:00:00`).format("MMMM DD")}
                </MDTypography>
                <MDTypography
                  variant="caption"
                  color="dark"
                  fontSize={'11px'}
                  sx={{ textTransform: 'uppercase',}}
                >
                  {`${moment(`${meeting?.date} ${meeting?.start_time}`).format("LT")} - 
                  ${moment(`${meeting?.date} ${meeting?.end_time}`).format("LT")}`}
                </MDTypography>
              </MDBox>
            </MDBox>
          </CardContent>
        </MDBox>
        {/* BUTTONS */}
        <Grid container py={'8px'} alignItems={"center"} justifyContent={'space-around'}>
          {/* STAR/STOP SHARING */}
          <Grid item xs={12} md={3}>
            <MDButton
              variant="contained"
              disabled={!!isServerStreaming && meetingId !== streamingId}
              onClick={() => {
                if (!isServerStreaming){
                  startCapture(meetingId)
                }else{
                  stopCapture()
                }
              }}
              widthButton={'100%'}
              size="small"
              sx={{ boxShadow: 2, "&:hover": { boxShadow: 3 } }}
            >
              <MDTypography
                variant="caption"
                color={ (!isServerStreaming || streamingId === meetingId) ? "primary": "secondary"}
                fontWeight="bold"
                fontSize="12px"
              >
                {(meetingIsStreaming) ? "Stop sharing" : "Start sharing"}
              </MDTypography>
              { (meetingIsStreaming) ?
                <img src={stop} alt="stop"/>
                :
                <img src={play} alt="play" />
              }
            </MDButton>
          </Grid>
          {/* PRESENTATION VIEW */}
          <Grid item xs={12} md={4} >
            <MDButton
              variant="contained"
              disabled={!(meetingIsStreaming || streaming)}
              onClick={() => {
                  if (meetingIsStreaming || streaming) {
                    setPresentationPressed(true)
                    window.open(`/meeting/${meeting.id}/display`, "_blank");
                  }
              }}
              size="small"
              widthButton={'100%'}
              // sx={{ boxShadow: 2, "&:hover": { boxShadow: 3 },  }}
              sx={!!(meetingIsStreaming || streaming) && !presentationPressed ? glowProps : {}}
            >
              <MDTypography
                variant="caption"
                color={ (meetingIsStreaming || streaming) ? "primary": "secondary"}
                fontWeight="bold"
                style={{marginRight: 5}}
              >
                Presentation View
              </MDTypography>
              { (meetingIsStreaming || streaming) ?
                <img src={newtab} alt="newtab"  />
              :
                <img src={newtab2} alt="newtab"  />
              }
            </MDButton>
          </Grid>
          {/* CLASS MANAGEMENT */}
          <Grid item xs={12} md={4}>
            <MDButton
              variant="contained"
              disabled={!(meetingIsStreaming || streaming)}
              onClick={() => {
                if (isServerStreaming || (meetingStatus?.started && !meetingStatus?.ended) || meetingIsStreaming || streaming) {
                  setManagementPressed(true)
                  window.open(`${ROUTES.COURSES}/${meeting.course.id}/admin/meeting/${meeting.id}`, "_blank");
                }
              }}
              size="small"
              widthButton={'100%'}
              // sx={{ boxShadow: 2, "&:hover": { boxShadow: 3 },  }}
              sx={!!(meetingIsStreaming || streaming) && !managementPressed ? glowProps : {}}
            >
              <MDTypography
                variant="caption"
                color={ (meetingIsStreaming || streaming) ? "primary": "secondary"}
                fontWeight="bold"
              >
                Class Management
              </MDTypography>
              { (meetingIsStreaming || streaming) ?
                <img src={tablet} alt="tablet"  />
                :
                <img src={tablet2} alt="tablet"  />
              }
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
    </Card>
  );
};

export default MeetingCard;
